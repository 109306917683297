/* eslint-disable */

import { FC } from 'react'
import './instructions.css'

const AppInstructionsPage: FC = () => {
  return (
    <>
      <div className="i22 doc-content">
        <h1 className="i8" id="h.o4xztpnb4lz4">
          <span className="i8">ApneaScan Mobile Application </span>
        </h1>
        <p className="i2">
          <span className="i5">- &nbsp;for use with the ApneaScan SaMD. </span>
        </p>
        <p className="i0">
          <span className="i1"></span>
        </p>
        <h2 className="i19" id="h.mu15erjbdnwp">
          <span className="i4 i13">Instructions for use</span>
        </h2>
        <p className="i0">
          <span className="i1"></span>
        </p>
        <p className="i2">
          <span className="i6">
            <a className="i12" href="mailto:support@apneascan.com">
              support@apneascan.com
            </a>
          </span>
        </p>
        <p className="i2">
          <span className="i6">www.apneascan.com</span>
        </p>
        <p className="i0">
          <span className="i1"></span>
        </p>
        <p className="i2">
          <span className="i1">
            The ApneaScan mobile application is the conduit by which sleep data
            recorded using an ApneaScan compatible recording device is uploaded
            to the ApneaScan SaMD.
          </span>
        </p>
        <p className="i0">
          <span className="i1"></span>
        </p>
        <p className="i2 i18">
          <span className="i1">
            The App is downloaded to a compatible mobile device from either the
            Google Play Store or the Apple App Store, then the Recorder is
            paired with the App using Bluetooth. The
          </span>
        </p>
        <p className="i2 i18">
          <span className="i1">
            phone collects the recording, then uploads the study automatically
            to the website. The Apneascan App does not store information on the
            user&rsquo;s identity or location. All data in the app is
            de-identified.
          </span>
        </p>
        <p className="i0">
          <span className="i1"></span>
        </p>
        <p className="i2">
          <span className="i1">
            If multiple studies are done on the same patient, studies appear in
            Apneascan one at a time. Accept or Reject each study in the Pending
            list until all studies have been accepted or rejected for report
            generation
          </span>
        </p>
        <p className="i0">
          <span className="i1"></span>
        </p>
        <h3 className="i3" id="h.3wju746z8b1o">
          <span className="i11 i4">App Feedback and Support: </span>
        </h3>
        <p className="i2">
          <span className="i1">
            Please contact support@apneascan.com with any questions and/or user
            feedback regarding the App. Include the following information:
          </span>
        </p>
        <p className="i2">
          <span className="i1">
            Apneascan Account Name, Admin Username, Patient ID, Recorder Serial
            Number, App version, Platform (iOS/Android), Phone make/model and a
            description of the issue encountered. Include screenshots/pictures,
            if applicable.
          </span>
        </p>
        <br />
        <p className="i0">
          <span className="i1"></span>
        </p>
        <h3 className="i3 i14" id="h.iauapkgxh8bq">
          <span className="i11 i4"></span>
        </h3>
        <h3 className="i3" id="h.a98mn0n1gw7l">
          <span className="i11 i4">App Download &amp; Installation</span>
        </h3>
        <p className="i2">
          <span className="i1">
            The Apneascan Mobile App can be found on the Google Play and Apple
            App Stores. In your device&rsquo;s settings, please ensure automatic
            updating is turned ON as we will continually release updates to
            improve user experience.
          </span>
        </p>
        <h3 className="i3 i14" id="h.877xeehbo85e">
          <span className="i11 i20"></span>
        </h3>
        <h3 className="i3" id="h.kfmpxyfqul33">
          <span className="i4">Patient Sign-In to App</span>
        </h3>
        <p className="i2">
          <span className="i1">
            &nbsp;After a Patient&rsquo;s profile has been created, an email is
            sent with the Patient ID &amp; App download instructions. The
            Patient ID and Date of Birth are to Sign into the App &bull; Enter
            Patient ID &bull; Enter Date of Birth &bull; Press Sign In button
          </span>
        </p>
        <p className="i0">
          <span className="i1"></span>
        </p>
        <p className="i0">
          <span className="i1"></span>
        </p>
        <p className="i0">
          <span className="i1"></span>
        </p>
        <h3 className="i3" id="h.s80e23q7yrtz">
          <span className="i4">To Pair Apneascan Rings:</span>
          <span className="i17">&nbsp;</span>
        </h3>
        <p className="i2">
          <span className="i1">
            1) Click the scan button (magnifying glass icon). 2) Turn on the
            recorder by placing a finger in the sensor, OR If you&rsquo;re using
            a CheckMe O2 wrist recorder, press the power button to turn on the
            recorder. 3) When your device Serial Number appears, press the
            button (chain-link icon).
          </span>
        </p>
        <h3 className="i3" id="h.fu8tpjc2zz5c">
          <span className="i11 i4">Patient Instructions </span>
        </h3>
        <p className="i2">
          <span className="i1">
            We encourage Clinicians to guide Patients through pairing the
            recorder to the mobile device and performing a brief test recording
            to verify function and to make sure they are comfortable with the
            process. (There may be a short study file uploaded from this test,
            if so, Reject the study)
          </span>
        </p>
        <p className="i0">
          <span className="i23"></span>
        </p>
        <h3 className="i3" id="h.qaj0kajv0dk1">
          <span className="i4 i11">Start a Recording:</span>
        </h3>
        <p className="i2">
          <span className="i1">
            When going to bed, the mobile device should be close by, plugged in
            to a charger and the Bluetooth turned on. Successful data collection
            in progress is indicated by SpO2 and Pulse Rate values being
            displayed both on the Recorder and in the App.
          </span>
        </p>
        <p className="i2">
          <span className="i1">-Start a Recording</span>
        </p>
        <p className="i2">
          <span className="i1">- Close all other apps </span>
        </p>
        <p className="i2">
          <span className="i1">-Open the Apneascan App </span>
        </p>
        <p className="i2">
          <span className="i1">-Turn on Bluetooth </span>
        </p>
        <p className="i2">
          <span className="i1">
            -Do not use other Bluetooth accessories during recording.
          </span>
        </p>
        <p className="i2">
          <span className="i1">
            -Press power button until display turns on then insert index finger
            or thumb into sensor.
          </span>
        </p>
        <p className="i0">
          <span className="i1"></span>
        </p>
        <p className="i2">
          <span className="i1">Press &ldquo;Start Study&rdquo; </span>
        </p>
        <p className="i0">
          <span className="i1"></span>
        </p>
        <p className="i2">
          <span className="i1">
            After the study is stopped in the app, remove the sensor and
            Recorder. The study will upload automatically.
          </span>
        </p>
        <h3 className="i3" id="h.80ozm0bnssr9">
          <span className="i11 i4">Troubleshooting </span>
        </h3>
        <h4 className="i15" id="h.osea0j6pxfv">
          <span className="i21 i20">Can&rsquo;t sign into App Reason: </span>
        </h4>
        <p className="i2">
          <span>Action: </span>
          <span className="i1">
            Either the wrong Patient ID or DOB were entered.{' '}
          </span>
        </p>
        <p className="i0">
          <span className="i1"></span>
        </p>
        <h4 className="i15" id="h.3b13mjah9rm8">
          <span className="i20 i21">No internet connection. </span>
        </h4>
        <p className="i2">
          <span className="i1">
            Action: Check the Patient&rsquo;s Date of Birth &amp; Patient ID
            against their profile in the Apneascan Account. Correct as needed.
            Make sure the mobile device has a stable internet connection.
          </span>
        </p>
        <h4 className="i15" id="h.ddhlopobxzw">
          <span className="i24">Data</span>
          <span className="i21 i20">&nbsp;did not upload </span>
        </h4>
        <p className="i2">
          <span className="i1">
            Reason: The study was too short. Internet connectivity was poor when
            the study ended.
          </span>
        </p>
        <p className="i0">
          <span className="i1"></span>
        </p>
        <p className="i2">
          <span className="i1">Action: Verify internet connection.</span>
        </p>
        <p className="i2">
          <span className="i1">&nbsp;Restart Apneascan App.</span>
        </p>
        <p className="i0">
          <span className="i1"></span>
        </p>
        <p className="i2">
          <span>
            &nbsp;If the issue is not resolved by having the patient ensure good
            internet connectivity then restarting the app, please send an email
            to the support team at: support@Apneascan.com containing the
            following information: &bull; Phone make/model: (example: Samsung
            Galaxy S10) &bull; Your account name: (example: ABC Dental Clinic)
            &bull; Patient ID: (from their profile in your account) &bull; Date
            of study: (date the study was started) &bull; Screenshots, if
            applicable &bull; Any observations/feedback provided by the patient
            regarding their experience, the Instructions, or any error messages
            seen.
          </span>
        </p>
      </div>
    </>
  )
}

export { AppInstructionsPage }
