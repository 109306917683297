import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { QueryClientProvider } from '@tanstack/react-query'
import { HelmetProvider } from 'react-helmet-async'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  AdminPage,
  HomePage,
  LoginPage,
  OrganisationPage,
  PatientDetailsPage,
  PatientsPage,
  PrivacyPolicyPage,
  SchedulePatientPage,
  SchedulingPage,
  TermsOfSServicePage,
  StaffAddPage,
  StaffEditPage,
  StaffsPage,
  NotFoundPage,
  AppInstructionsPage,
} from './containers'
import { queryClient } from './lib'
import { ModalProvider } from './components'
import './App.css'
import { useClientAuthentication } from './hooks'

function App() {
  /**
   * Handle auth state
   */
  useClientAuthentication()

  return (
    <>
      <div className="App">
        <QueryClientProvider client={queryClient}>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <HelmetProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/staffs" element={<StaffsPage />} />
                <Route path="/scheduling" element={<SchedulingPage />} />
                <Route
                  path="/scheduling/patients"
                  element={<SchedulePatientPage />}
                />
                <Route path="/staff/add" element={<StaffAddPage />} />
                <Route path="/staff/edit" element={<StaffEditPage />} />
                <Route path="/patients" element={<PatientsPage />} />
                <Route
                  path="/patients/details"
                  element={<PatientDetailsPage />}
                />
                <Route path="/admins" element={<AdminPage />} />
                <Route path="/organisations" element={<OrganisationPage />} />
                <Route path="/tos" element={<TermsOfSServicePage />} />
                <Route path="/policy" element={<PrivacyPolicyPage />} />
                <Route path="/instructions" element={<AppInstructionsPage />} />
                <Route element={<NotFoundPage />} path="*" />
              </Routes>
              <ModalProvider />
            </BrowserRouter>
          </HelmetProvider>
        </QueryClientProvider>
      </div>
    </>
  )
}

export default App
