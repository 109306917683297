import { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import { Button } from 'src/components'
import { APP_TITLE, ERROR_MESSAGES } from 'src/constants'
import { Result } from 'antd'
import { useNavigate } from 'react-router'

const MainContainer = styled.main`
  text-align: center;
  margin-top: 4rem;
`
const NotFoundPage: FC = () => {
  const navigate = useNavigate()
  return (
    <>
      <Helmet>
        <title>Terms of Service | {APP_TITLE}</title>
      </Helmet>
      <MainContainer>
        <Result
          status="404"
          title="404 - Page Not Found"
          subTitle={ERROR_MESSAGES.PAGE_NOT_FOUND}
          extra={
            <Button
              type="primary"
              label="Take me back to Homepage"
              onClick={() => navigate('..', { relative: 'path' })}
            />
          }
        />
      </MainContainer>
    </>
  )
}

export { NotFoundPage }
