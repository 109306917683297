import { FC } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import {
  Button,
  Select,
  Text,
  TextField,
  PageHeader,
  CheckBox,
  PrivateRoute,
} from '../../components'
import { IPatientSchedule } from '../../interfaces'
import { patientService } from '../../services'
import { VALIDATION_SCHEMA } from '../../utils'
import { ERROR_MESSAGES } from '../../constants'
import { colors } from '../../theme'

const MainContainer = styled.section`
  & .page-header {
    display: flex;
    align-items: center;
    & .icon-back {
      margin-right: 0.5rem;
    }
  }
  & form {
    & .field-wrapper {
      display: flex;
      min-height: 60vh;
      gap: 2rem;
      background: ${colors.white};
      padding: 1.5rem 2rem;
      border-radius: 8px;
      box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
      margin-top: 2rem;
      & .form-fields {
        margin-bottom: 1.5rem;
      }
      & > div {
        flex: 1;
      }
    }
    & .btn-wrapper {
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;
      margin-top: 1rem;
    }
  }
`

interface ISchedulepage {
  id?: boolean
}

const SchedulePatientPageComponent: FC<ISchedulepage> = () => {
  const navigate = useNavigate()

  const { control, handleSubmit } = useForm<IPatientSchedule>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      dob: '',
      height: '',
      weight: '',
      phone: '',
      startDate: '',
      numberOfNights: 0,
    },
    mode: 'all',
    resolver: zodResolver(VALIDATION_SCHEMA.userValidationSchema),
  })

  const addPatient = useMutation({
    mutationKey: ['addPatient'],
    mutationFn: patientService.addPatient,

    onError: (err: Error) => {
      toast.error(err?.message || ERROR_MESSAGES.ERROR_OCCURED)
    },
  })

  const handlePatientSubmit = async (payload: IPatientSchedule) => {
    try {
      const savePayload = {
        ...payload,
      }

      addPatient.mutate(savePayload)
    } catch (err) {
      toast.error(ERROR_MESSAGES.ERROR_OCCURED)
    }
  }
  return (
    <>
      <Helmet>
        <title>{'User Add'}</title>
      </Helmet>
      <MainContainer>
        <PageHeader />
        <section>
          <div className="page-header">
            <ArrowLeftOutlined
              onClick={() => navigate('/users')}
              className="icon-back"
            />
            <Text content={'Add User'} preset="headingSmall" />
          </div>
          <form className="form">
            <div className="field-wrapper">
              <div>
                <Controller
                  name="firstName"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <TextField
                      placeholder="Enter username"
                      label="Username"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={error?.message}
                      className="form-fields"
                    />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <TextField
                      placeholder="Enter email"
                      label="Email"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={error?.message}
                      className="form-fields"
                    />
                  )}
                />
                <Controller
                  name="lastName"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <TextField
                      placeholder="Enter last name"
                      label="Last name"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={error?.message}
                      className="form-fields"
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  name="dob"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <TextField
                      placeholder="Enter dob"
                      label="Date of birth"
                      value={value}
                      required
                      onChange={onChange}
                      onBlur={onBlur}
                      error={error?.message}
                      className="form-fields"
                    />
                  )}
                />
                <Controller
                  name="height"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <Select
                      placeholder="Select height"
                      label="Height"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={error?.message}
                      className="form-fields"
                    />
                  )}
                />
                <Controller
                  name="weight"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <TextField
                      placeholder="Enter weight"
                      label="Weight"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={error?.message}
                      className="form-fields"
                    />
                  )}
                />
              </div>
            </div>

            <div>
              <Button label="Calculate BMI" />
              <TextField placeholder="Enter BMI" />
            </div>

            <Controller
              name="phone"
              control={control}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <TextField
                  placeholder="Enter phone"
                  label="Phone"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  className="form-fields"
                />
              )}
            />

            <Controller
              name="email"
              control={control}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <TextField
                  placeholder="Enter email"
                  label="Email"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  className="form-fields"
                />
              )}
            />

            <div>
              <CheckBox />
              <Text content="Consents to text" />
            </div>

            <Text content="Scheduling Details" preset="headinglarge" />
            <Controller
              name="startDate"
              control={control}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <TextField
                  placeholder="Enter email"
                  label="Email"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  className="form-fields"
                />
              )}
            />
            <div className="btn-wrapper">
              <Button label="Cancel" variant="link" />
              <Button
                label="Save"
                onClick={handleSubmit(handlePatientSubmit)}
                variant="primary"
              />
            </div>
          </form>
        </section>
      </MainContainer>
    </>
  )
}

export const SchedulePatientPage = PrivateRoute(SchedulePatientPageComponent)
