import { MutationFunction, QueryFunction } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { IRole } from 'src/interfaces'
import { API } from 'src/lib'

const addRole: MutationFunction<{ data: string }, IRole> = async reqPayload => {
  return API.post('/role', reqPayload)
}

const assignRole: MutationFunction<
  { data: string },
  any
> = async reqPayload => {
  return API.post('/role/permissions', reqPayload)
}

const fetchRoles: QueryFunction<IRole[]> = async () => {
  const { data } = await API.get(`/role/all`, {})
  return data
}

const deleteRole: MutationFunction<AxiosResponse, string> = id => {
  return API.delete(`/role/${id}`)
}

export const roleService = {
  addRole,
  assignRole,
  fetchRoles,
  deleteRole,
}
