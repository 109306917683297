import { Table } from 'antd'
import { TablePaginationConfig } from 'antd/lib/table'
import type { ColumnGroupType, ColumnType } from 'antd/es/table'
import { SpinProps } from 'antd/lib/spin'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { TableLocale } from 'antd/lib/table/interface'
import styled from 'styled-components'
import { colors } from '../../../theme'

const TableWrapper = styled(Table)`
  overflow-x: auto;
  min-height: 300px;
  background: ${colors.white};
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 3%);
  border-radius: 5px;
  & thead > tr > th {
    color: ${colors.darkGrey3};
    height: 54px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    white-space: nowrap;
    word-break: break-word;
    word-break: break-all;
  }
  & .ant-table-row {
    height: 55px;
  }
  & .ant-table-container table > thead > tr:first-child th:first-child,
  .ant-table-container table > tbody > tr > td:first-child {
    padding-left: 35px;
  }
  & .ant-table-tbody {
    & > :nth-child(even) {
      background: #f9f9f9;
    }
    & .ant-table-row {
      & .ant-table-cell {
        font-size: 14px !important;
        vertical-align: top;
        color: ${colors.black};
        padding-top: 16px;
        & .ant-tag {
          font-size: 14px;
        }
      }
    }
    & .ant-table-row:hover > td {
      background: unset;
    }
  }
`

interface IScroll {
  scrollToFirstRowOnChange?: boolean
  x: string | number | true
  y: string | number
}

interface ITableProps {
  key?: string
  pagination?: false | TablePaginationConfig
  loading?: boolean | SpinProps
  size?: SizeType
  bordered?: boolean
  locale?: TableLocale
  scroll?: IScroll
  tableLayout?: 'auto' | 'fixed'
  className?: string
  columns: (ColumnGroupType<object> | ColumnType<object>)[]
  dataSource: object[]
  onChange?: (pagination, filters, sorter) => void
}

function TableComponent({
  key,
  dataSource,
  columns,
  pagination,
  scroll,
  loading,
  size,
  bordered,
  locale,
  onChange,
  tableLayout,
  className,
}: ITableProps) {
  return (
    <TableWrapper
      key={key}
      dataSource={dataSource}
      columns={columns}
      pagination={pagination}
      loading={loading}
      size={size}
      bordered={bordered}
      scroll={scroll}
      locale={locale}
      onChange={onChange}
      tableLayout={tableLayout}
      className={className}
    />
  )
}

export { TableComponent as Table }
