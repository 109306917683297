import { FC } from 'react'
import styled from 'styled-components'
import { Text } from '../../atom'

const MainWrapper = styled.div`
  margin-top: 4rem;
  & .container {
    text-align: center;
    & .footer {
      & .left-side {
        display: none;
      }
      & .right-side {
      }
    }
    & .links {
      & .social {
        display: flex;
        justify-content: center;
        gap: 1rem;
        padding-bottom: 1rem;
      }
    }
    & .copyright {
      margin-top: 1rem;
      background-color: rgb(242, 248, 255);
      padding: 1rem 0;
    }
  }
  @media (width > 768px) {
    & .container {
      & .footer {
        justify-content: space-between;
        margin: 0 auto;
        display: flex;
        max-width: 1248px;

        & .left-side {
          display: block;
          img {
            width: 250px;
          }
        }
        & .right-side {
        }
      }
      & .links {
        & .social {
          img {
            cursor: pointer;
          }
        }
      }
      & .copyright {
      }
    }
  }
`

const ContactSection: FC = () => {
  return (
    <MainWrapper>
      <div className="container">
        <div className="footer">
          <div className="left-side">
            <img
              src="/assets/imgs/logo.webp"
              alt="logo"
              width="100px"
              height="auto"
            />
          </div>
          <div className="right-side">
            <Text content="Contact Us" preset="headinglarge" />
            <Text content="sales@apneascan.com" preset="bodyLargeSemiBold" />
            <Text content="12405 SE 2nd Cir" preset="bodyLargeSemiBold" />
            <Text
              content="Vancouver, WA 98607 USA"
              preset="bodyLargeSemiBold"
            />
          </div>
        </div>
        <div className="links">
          <div className="social">
            {/* <img
              src="/assets/icons/facebook.png"
              alt="facebook"
              width={32}
              height="auto"
            />
            <img
              src="/assets/icons/instagram.png"
              alt="instagram"
              width={32}
              height="auto"
            />
            <img
              src="/assets/icons/twitter.png"
              alt="twitter"
              width={32}
              height="auto"
            /> */}
          </div>
          <div className="copyright">
            © {new Date().getFullYear()} ApneaScan. All Rights Reserved.
          </div>
        </div>
      </div>
    </MainWrapper>
  )
}

export { ContactSection }
