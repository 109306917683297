import { FC } from 'react'
import { Menu } from 'antd'
import { useNavigate } from 'react-router'
import type { MenuProps } from 'antd'
import { MenuMode } from 'rc-menu/lib/interface'
import { MenuTheme } from 'antd/lib/menu/MenuContext'

interface MenuGeneratorProps {
  theme?: MenuTheme
  mode?: MenuMode
  className?: string
  defaultSelectedKeys?: string[]
  defaultOpenKeys?: string[]
  menus?: Array<MenuItem & { target: string }>
  multiple?: boolean
  onSelect?: () => void
  selectedKeys?: string[]
  openKeys?: any
  forceSubMenuRender?: boolean
}
interface MenuItemProps {
  key: string
  name: string
  onClick?: () => void
  icon?: JSX.Element
  className?: string
  children?: MenuItem[]
  disabled?: boolean
}

type MenuItem = Required<MenuProps>['items'][number]

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem
}

const renderMenu = (menus: MenuItemProps[]): MenuProps['items'] => {
  const menuItems: MenuProps['items'] = []
  for (const menu of menus) {
    menuItems.push(getItem(menu.name, menu.key, menu.icon))
  }
  return menuItems
}

const MenuGenerator: FC<MenuGeneratorProps> = ({
  menus,
  mode = 'inline',
  theme,
  className,
  defaultSelectedKeys,
  defaultOpenKeys,
  multiple,
  onSelect,
  selectedKeys,
  openKeys,
  forceSubMenuRender,
}) => {
  const navigate = useNavigate()
  return (
    <Menu
      onClick={({ key }) => {
        const { target } = menus?.find(item => item?.key === key) || {}
        if (target) {
          navigate(target)
        }
      }}
      theme={theme}
      mode={mode}
      className={className}
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      openKeys={openKeys}
      multiple={multiple}
      onSelect={onSelect}
      selectedKeys={selectedKeys}
      forceSubMenuRender={forceSubMenuRender}
      items={renderMenu(menus as any) as any}
    >
      {/* {menus && renderMenu(menus)} */}
    </Menu>
  )
}

export { MenuGenerator }
