import { FC } from 'react'
import styled from 'styled-components'
import { Text } from '../../atom'

const Wrapper = styled.header`
  margin-bottom: 1.5rem;
`

const PageHeader: FC = () => {
  return (
    <Wrapper>
      <Text content="City of Hope" preset="headingMedium" />
    </Wrapper>
  )
}

export { PageHeader }
