import { FC, useCallback } from 'react'
import { styled } from 'styled-components'
import { useSnapshot } from 'valtio'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { authStore, modalStore } from 'src/store'
import { ModalBase, Text } from 'src/components'
import { colors } from 'src/theme'
import { clearLocalStorage } from 'src/utils'

const Wrapper = styled.div`
  margin-top: 28px;
  margin-bottom: 28px;
`

const LogOutModal: FC = () => {
  const { shouldOpen } = useSnapshot(modalStore.logOut)
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const handleSignOut = useCallback(() => {
    modalStore.logOut.close()
    authStore.setLogout()
    queryClient.clear()
    queryClient.invalidateQueries()
    authStore.setDbUser(null)
    clearLocalStorage()
    toast.success('Logged out successfully')
    navigate('/login')
  }, [])

  return (
    <ModalBase
      title="Sign Out"
      open={shouldOpen}
      onOk={handleSignOut}
      width="503px"
      okButtonProps={{
        style: {
          background: colors.green,
          color: colors.white,
        },
      }}
      onCancel={() => modalStore.logOut.close()}
      okText="Yes, proceed"
    >
      <Wrapper>
        <Text content="Are you sure that you want to sign out ?" />
      </Wrapper>
    </ModalBase>
  )
}

export { LogOutModal }
