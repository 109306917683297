import {
  IRoleActionKeys,
  IRoleActions,
  IRoleKeys,
  IRoleSlug,
} from 'src/interfaces'

export const SUPER_ADMIN = 'super_admin'

/**
 * Actions that a role can perform
 */
const ACTION: { [key in IRoleActionKeys]: IRoleActions } = {
  READ: 'read',
  ADD: 'add',
  UPDATE: 'update',
  DELETE: 'delete',
}

const ROLES: {
  [key in IRoleKeys]: IRoleSlug
} = {
  Admins: 'admins',
  STAFFS: 'staffs',
  PATIENTS: 'patients',
  SCHEDULING: 'scheduling',
}

const ROLE_OPTIONS: Array<{ name: string; value: IRoleSlug }> = [
  // {
  //   name: 'Admins',
  //   value: ROLES.Admins,
  // },
  {
    name: 'Staffs',
    value: ROLES.STAFFS,
  },
  {
    name: 'Patients',
    value: ROLES.PATIENTS,
  },
  {
    name: 'Scheduling',
    value: ROLES.SCHEDULING,
  },
]

const ROLE_ACTIONS: Array<{ label: string; value: IRoleActions }> = [
  {
    label: 'Read',
    value: ACTION.READ,
  },
  {
    label: 'Add',
    value: ACTION.ADD,
  },
  {
    label: 'Update',
    value: ACTION.UPDATE,
  },
  {
    label: 'Delete',
    value: ACTION.DELETE,
  },
]

type PermissionTypes = 'NO_PERMISSION' | 'ALL_PERMISSION'

type PermissionValues = '0000' | '1111'

const PERMISSIONS_TYPE: Record<PermissionTypes, PermissionValues> = {
  NO_PERMISSION: '0000',
  ALL_PERMISSION: '1111',
}

export { ROLES, ROLE_ACTIONS, ACTION, ROLE_OPTIONS, PERMISSIONS_TYPE }
