import { QueryCache, QueryClient } from '@tanstack/react-query'

export const queryCache = new QueryCache()

/**
 * Create a new query client instance
 */
const queryClient = new QueryClient({
  queryCache: queryCache,
  defaultOptions: {
    queries: {
      staleTime: 4 * 60 * 1000,
    },
    mutations: {},
  },
})

export { queryClient }
