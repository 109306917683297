const SUCCESS_MESSAGES = {
  LOGIN_SUCCESS: 'Logged in successfully',
  LOGOUT_SUCCESS: 'Logged out successfully',
  CHANGE_PASSWORD: 'Password changed successfully',
  ADD_ORGANISATION: 'Organisation Added successfully',
  ADD_CLINIC: 'Clinic Added successfully',
  ADD_USER_GROUP: ' User group added successfully',
  ADD_ROLE: 'Role added successfully',
  ASSIGN_ROLE: 'Role assigned to user group successfully',
} as const

const ERROR_MESSAGES = {
  INVALID_EMAIL: 'Failed to verify email address',
  UNAUTHORIZED_USER: 'Unauthorised user. Please try again later',
  ERROR_OCCURED: 'Error occured. Please try again later',
  PAGE_NOT_FOUND: 'Oops! the page you are looking for does not exist',
  RESULT_EMPTY: `Sorry we couldn't find any results`,
} as const

export { ERROR_MESSAGES, SUCCESS_MESSAGES }
