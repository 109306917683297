import React, { ForwardedRef, ReactNode, forwardRef } from 'react'
import { Input, InputProps } from 'antd'
import styled from 'styled-components'
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
import { colors } from '../../../theme'
import { Text } from '../Text'

const { TextArea, Password } = Input

type InputSize = 'large' | 'middle' | 'small'

type InputType =
  | 'textarea'
  | 'number'
  | 'text'
  | 'password'
  | 'email'
  | 'search'
  | 'tel'

interface TextFieldProps extends InputProps {
  type?: InputType
  minRows?: number
  maxRows?: number
  label?: string
  required?: boolean
  allowClear?: boolean
  defaultValue?: string
  disabled?: boolean
  maxLength?: number
  prefix?: ReactNode
  size?: InputSize
  error?: string | boolean
  placeholder?: string
  onSearch?: (value, event) => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  value?: string | number
  loading?: boolean
  showCount?: boolean
  name?: string
  className?: string
  width?: string
  height?: string
  autoComplete?: 'on' | 'off' | 'new-password'
  ref?: React.ForwardedRef<HTMLElement>
  onPressEnter?: () => void
  autoFocus?: boolean
  borderradius?: string
}

const TextFieldWrapper = styled.div<TextFieldProps & { $borderradius: string }>`
  width: ${({ width }: TextFieldProps) => {
    return width ? `${width} !important` : '100%'
  }};
  & .ant-input {
    border-radius: ${({ $borderradius }) => {
      return $borderradius ? $borderradius : '8px !important'
    }};
    text-indent: 4px;
    border-right: ${({ addonAfter }: TextFieldProps) => {
      return addonAfter && `none !important`
    }};
    caret-color: ${colors.darkBlue};
    background: ${colors.white};

    height: ${({ prefix, suffix, height }: TextFieldProps) => {
      return prefix || suffix ? 'none' : height ? height : `40px`
    }};
    border: ${({ error }: TextFieldProps) => {
      return error ? `1px solid ${colors.red}` : `1px solid ${colors.grey}`
    }};
    border-top-left-radius: ${({ prefix }: TextFieldProps) => {
      return prefix && '0 !important'
    }};
    border-bottom-left-radius: ${({ prefix }: TextFieldProps) => {
      return prefix && '0 !important'
    }};
    border-top-right-radius: ${({ suffix }: TextFieldProps) => {
      return suffix && '0 !important'
    }};
    border-bottom-right-radius: ${({ suffix }: TextFieldProps) => {
      return suffix && '0 !important'
    }};
    color: ${colors.black};
    &::placeholder {
      color: ${colors.darkGrey1};
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      /* stylelint-disable */
      border: ${({ error }: TextFieldProps) => {
        return error ? `1px solid ${colors.red}` : `1px solid ${colors.blue}`
      }};
      border-top: ${({ prefix, suffix }: TextFieldProps) => {
        return (prefix || suffix) && 'none !important'
      }};
      border-bottom: ${({ prefix, suffix }: TextFieldProps) => {
        return (prefix || suffix) && 'none !important'
      }};
      border-right: ${({ suffix }: TextFieldProps) => {
        return suffix && `none !important`
      }};
      border: ${({ prefix, suffix }: TextFieldProps) => {
        return (prefix || suffix) && `none`
      }};
    }
  }
  & .ant-input-affix-wrapper-focused {
    box-shadow: none !important;
    border-color: ${colors.blue};
  }

  & .ant-input-affix-wrapper {
    border-radius: ${({ $borderradius }) => {
      return $borderradius ? $borderradius : '8px !important'
    }};
    padding: 0;
    border: ${({ error }: TextFieldProps) => {
      return error ? `1px solid ${colors.red}` : `1px solid ${colors.grey}`
    }};
    background: ${colors.white};
    height: ${({ height }: TextFieldProps) => {
      return height ? height : '40px'
    }};

    &:hover,
    &:focus,
    &:active {
      border: ${({ error }: TextFieldProps) => {
        return error ? `1px solid ${colors.red}` : `1px solid ${colors.blue}`
      }};
    }
  }

  .ant-input-suffix {
    padding-right: 15px;
    margin-inline-start: 0;
    border-top-right-radius: ${({ $borderradius }) => {
      return $borderradius ? $borderradius : '8px !important'
    }};
    border-bottom-right-radius: ${({ $borderradius }) => {
      return $borderradius ? $borderradius : '8px !important'
    }};
    background: ${colors.white};
    border-top-right-radius: ${({ error, $borderradius }) => {
      return !error && $borderradius ? $borderradius : '8px'
    }};
    border-bottom-right-radius: ${({ error, $borderradius }) => {
      return !error && $borderradius ? $borderradius : '8px'
    }};
  }

  .ant-input-prefix {
    padding-left: 15px;
    margin-inline-end: 0;
    border-top-left-radius: ${({ $borderradius }) => {
      return $borderradius ? $borderradius : '8px !important'
    }};
    border-bottom-left-radius: ${({ $borderradius }) => {
      return $borderradius ? $borderradius : '8px !important'
    }};
    background: ${colors.white};
    border-top-left-radius: ${({ error, $borderradius }) => {
      return !error && $borderradius ? $borderradius : '8px'
    }};
    border-bottom-left-radius: ${({ error, $borderradius }) => {
      return !error && $borderradius ? $borderradius : '8px'
    }};
  }
`

const StyledInput = styled(Input)<TextFieldProps>``

const StyledPassword = styled(Password)<
  TextFieldProps & { $borderradius: string }
>`
  box-shadow: none;
  height: ${({ height }: TextFieldProps) => {
    return height ? `${height}` : '40px'
  }};
  border-radius: 8px;
  & .ant-input {
    border-bottom: 1px solid ${colors.grey} !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    text-indent: 15px;
    height: ${({ height }: TextFieldProps) => {
      return height ? `${height}` : '39px !important'
    }};
    border-bottom: ${({ error }: TextFieldProps) => {
      return error ? `1px solid ${colors.red} !important` : `none important`
    }};
    &:hover,
    &:focus {
      border-bottom: ${({ error }: TextFieldProps) => {
        return error
          ? `1px solid ${colors.red} !important`
          : `1px solid ${colors.blue}!important`
      }};
    }
  }
  & .ant-input-suffix {
    border-top-right-radius: ${({ $borderradius }) => {
      return $borderradius ? $borderradius : '70px !important'
    }};
    border-bottom-right-radius: ${({ $borderradius }) => {
      return $borderradius ? $borderradius : '70px !important'
    }};
  }
  & .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
  & .ant-input-affix-wrapper {
    border: 1px solid ${colors.blue}!important;
  }

  & input {
    &:hover,
    &:active,
    &:focus {
      border: none !important;
    }
  }
  &:hover,
  &:focus,
  &:active,
  &:focus-within {
    box-shadow: none !important;
  }
`

const StyledTextArea = styled(TextArea)<TextFieldProps>`
  resize: none;
`

const StyledLabel = styled.div<TextFieldProps>`
  margin-bottom: 4px;
  text-align: left;
  display: flex;
  align-items: center;
  & label {
    font-size: 14px;
    padding-right: 5px;
    font-weight: 500;
    color: ${colors.black};
  }
  & .required {
    color: ${colors.red};
    font-size: 14px;
    margin-left: 3px;
  }
`

const Error = styled(Text)`
  color: ${colors.red};
  margin-bottom: 0;
  margin-top: 2px;
  text-align: left;
  margin-left: 2px;
`

const TextField = forwardRef(
  (
    {
      minRows,
      maxRows,
      type,
      label,
      required,
      error,
      maxLength,
      value,
      onChange,
      className,
      onBlur,
      borderradius,
      onPressEnter,
      autoComplete = 'off',
      ...rest
    }: TextFieldProps,
    ref: ForwardedRef<HTMLElement>,
  ) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (type === 'tel') {
        const { value } = e.target
        const isNum = Number.isInteger(Number(value))
        if (!isNum) return
        onChange && onChange(e)
      } else {
        onChange && onChange(e)
      }
    }

    const getInputField = () => {
      switch (type) {
        case 'textarea':
          return (
            <TextFieldWrapper
              className={className}
              $borderradius={borderradius!}
              error={error}
              {...rest}
            >
              {label && (
                <StyledLabel>
                  <Text content={label} preset="bodyLargeMedium" />
                  {required && <span className="required">*</span>}
                </StyledLabel>
              )}
              <StyledTextArea
                autoSize={{ minRows: minRows || 2, maxRows: maxRows || 4 }}
                maxLength={maxLength}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                autoComplete={autoComplete}
                {...(rest as any)}
              />
              {error && (
                <Error
                  preset="bodyMediumMedium"
                  content={error as string}
                ></Error>
              )}
            </TextFieldWrapper>
          )
        case 'password':
          return (
            <TextFieldWrapper
              className={className}
              $borderradius={borderradius!}
              error={error}
              {...rest}
            >
              {label && (
                <StyledLabel>
                  <Text content={label} preset="bodyLargeMedium" />
                  {required && <span className="required">*</span>}
                </StyledLabel>
              )}
              <StyledPassword
                maxLength={maxLength}
                type={type}
                value={value}
                error={!!error}
                onChange={onChange}
                $borderradius={borderradius!}
                onBlur={onBlur}
                autoComplete={'new-password'}
                iconRender={visible =>
                  visible ? (
                    <EyeTwoTone
                      style={{
                        color: colors.darkGrey2,
                      }}
                    />
                  ) : (
                    <EyeInvisibleOutlined
                      style={{
                        color: colors.darkGrey2,
                      }}
                    />
                  )
                }
                {...rest}
              />
              {error && (
                <Error
                  preset="bodyMediumMedium"
                  content={error as string}
                ></Error>
              )}
            </TextFieldWrapper>
          )
        default:
          return (
            <TextFieldWrapper
              className={className}
              $borderradius={borderradius!}
              error={error}
              {...rest}
            >
              {label && (
                <StyledLabel>
                  <Text content={label} preset="bodyLargeMedium" />
                  {required && <span className="required">*</span>}
                </StyledLabel>
              )}
              <StyledInput
                maxLength={maxLength}
                type={type}
                value={value}
                onChange={handleChange}
                onBlur={onBlur}
                onPressEnter={onPressEnter}
                autoComplete={autoComplete}
                ref={ref}
                {...rest}
              />
              {error && (
                <Error
                  preset="bodyMediumRegular"
                  content={error as string}
                ></Error>
              )}
            </TextFieldWrapper>
          )
      }
    }

    return getInputField()
  },
)

export { TextField }
