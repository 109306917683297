import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router'
import { Helmet } from 'react-helmet-async'
import { Popconfirm, Space, Tabs, Tag } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { staffsService } from 'src/services'
import { useStaffs } from 'src/queries'
import { useDebounce } from 'src/hooks'
import {
  Button,
  Divider,
  Pagination,
  PrivateRoute,
  Table,
  Text,
  TextField,
} from '../../components'
import { colors } from '../../theme'
import { modalStore } from '../../store'
import {
  APP_TITLE,
  ERROR_MESSAGES,
  QUERY_KEYS,
  SUPER_ADMIN,
} from 'src/constants'

const TABS_KEYS = {
  ARCHIVED: 'archived',
  ACTIVE: 'active',
}

const MainContainer = styled.main`
  & .btn--delete {
    color: ${colors.red};
  }
`

const PageActionsWrapper = styled.div`
  display: flex;
  gap: 1rem;
`

const PAGE_SIZE = 12

const UserPageComponent = () => {
  const navigate = useNavigate()
  const [archived, setArchived] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearch = useDebounce(searchQuery, 400)
  const [currentPage, setCurrentPage] = useState(1)
  const { state } = useLocation()
  const queryClient = useQueryClient()

  const { data: staffsData } = useStaffs({
    enabled: true,
    searchQuery: debouncedSearch,
    pageSize: PAGE_SIZE,
    page: currentPage,
    archived,
  })

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    currentPage !== 1 && navigate('/staffs', { state: { page: 1 } })
    currentPage !== 1 && setCurrentPage(1)
    setSearchQuery(e.target.value)
  }

  const deleteMutation = useMutation({
    mutationKey: ['archiveStaff'],
    mutationFn: staffsService?.archiveStaff,
    onSuccess: () => {
      toast.success('Staff archived successfully')
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.FETCH_STAFFS] })
    },
    onError: (err: AxiosError) => {
      toast.error(err.message || ERROR_MESSAGES.ERROR_OCCURED)
    },
  })

  const handleStaffArchive = (staffId: string) => {
    deleteMutation.mutate(staffId)
  }

  const tableHead = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, data) => {
        return (
          <span>
            {data?.firstName} {data?.lastName}
          </span>
        )
      },
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'userGroup',
      key: 'userGroup',
      render: userGroup => {
        return (
          <Tag color={userGroup.name === SUPER_ADMIN ? 'green' : 'gold'}>
            {userGroup.name}
          </Tag>
        )
      },
    },
    {
      title: 'Organisation',
      dataIndex: 'organization',
      key: 'organization',
      render: organization => {
        return <span>{organization?.name}</span>
      },
    },
    {
      title: 'Clinic',
      dataIndex: 'clinic',
      key: 'clinic',
      render: clinic => {
        return <span>{clinic?.name}</span>
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => {
        return <span>{format(createdAt, 'yyyy/MM/dd hh:mm a')}</span>
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      render: (id, data) => {
        return (
          <Space size="middle">
            <>
              <Button
                label="Reset Password"
                variant="basic"
                borderRadius="5px"
                color={colors.darkGrey4}
                onClick={() => {
                  modalStore.changePassword.setStaffId(id)
                  modalStore.changePassword.open()
                }}
                height="40px"
                width="160px"
              />
            </>

            {data?.userGroup?.slug !== SUPER_ADMIN && (
              <EditOutlined
                title={'Edit Staff'}
                onClick={() => navigate('/staff/edit', { state: { id } })}
              />
            )}

            {!data?.disabled && data?.userGroup?.slug !== SUPER_ADMIN && (
              <Popconfirm
                placement="topLeft"
                title="Are you sure you want to archive this staff ?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleStaffArchive(id)}
              >
                <DeleteOutlined className="btn--delete" title="Archive Staff" />
              </Popconfirm>
            )}
          </Space>
        )
      },
    },
  ]

  const items = [
    {
      label: `ACTIVE`,
      key: TABS_KEYS.ACTIVE,
      children: (
        <>
          <Table
            dataSource={staffsData?.staffs || []}
            columns={tableHead}
            pagination={false}
          />
        </>
      ),
    },
    {
      label: `ARCHIVED`,
      key: TABS_KEYS.ARCHIVED,
      children: (
        <Table
          dataSource={staffsData?.staffs || []}
          columns={tableHead}
          pagination={false}
        />
      ),
    },
  ]

  const pageActions = (
    <PageActionsWrapper>
      <TextField
        placeholder="Search"
        type="search"
        value={searchQuery}
        onChange={handleQueryChange}
      />
      <Button
        label="Add Staff"
        onClick={() => navigate('/staff/add')}
        width="180px"
      />
    </PageActionsWrapper>
  )

  useEffect(() => {
    if (state?.page) {
      setCurrentPage(state?.page)
    }
  }, [state?.page])

  return (
    <>
      <Helmet>
        <title>Staffs | {APP_TITLE}</title>
      </Helmet>
      <MainContainer>
        <section>
          <div className="page-header">
            <Text content="Settings" preset="headingSmall" />
          </div>
          <Divider height="2rem" />
          <div className="table-wrapper">
            <Tabs
              tabBarExtraContent={pageActions}
              items={items}
              onChange={e => setArchived(e === TABS_KEYS.ARCHIVED)}
            />
            <Pagination
              current={+currentPage}
              hideOnSinglePage
              total={staffsData?.count || 0}
              pageSize={PAGE_SIZE}
              onChange={page => {
                setCurrentPage(page)
                navigate('/staffs', { state: { page } })
              }}
              showSizeChanger={false}
            />
          </div>
        </section>
      </MainContainer>
    </>
  )
}

export const StaffsPage = PrivateRoute(UserPageComponent)
