import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS, SUPER_ADMIN } from 'src/constants'
import { IPermission, IUserGroup } from 'src/interfaces'
import { userGroupService } from 'src/services'

/**
 * useUserGroup
 *
 * @param enabled => query enable/disabled boolean
 * @param searchQuery => search string
 * @returns useQuery response
 */
export const useUserGroup = ({
  enabled,
  searchQuery,
}: {
  enabled: boolean
  searchQuery?: any
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.FETCH_USER_GROUP, 1, 12, searchQuery],
    queryFn: userGroupService.fetchUserGroup,
    enabled: enabled,
    refetchOnWindowFocus: false,
    retry: 1,
    select: response =>
      response
        ?.filter(userGroup => userGroup.slug != SUPER_ADMIN)
        ?.map((userGroup: IUserGroup) => {
          return {
            key: userGroup?.id,
            id: userGroup?.id,
            slug: userGroup?.slug,
            name: userGroup?.name,
            createdAt: userGroup?.createdAt,
          }
        }),
  })
}

/**
 * useUserGroup
 *
 * @param enabled => query enable/disabled boolean
 * @param id => search string
 * @returns useQuery response
 */
export const useUserGroupPermissions = ({
  enabled,
  id,
}: {
  enabled: boolean
  id?: any
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.FETCH_USER_GROUP_PERMISSION],
    queryFn: () => userGroupService.fetchUserGroupPermissions(id),
    enabled: enabled,
    refetchOnWindowFocus: false,
    retry: 1,
    select: response =>
      response?.map((permission: IPermission) => {
        return {
          key: permission?.id,
          id: permission?.id,
          userGroupId: permission?.userGroupId,
          roleId: permission?.roleId,
          value: permission?.value,
        }
      }),
  })
}
