/* eslint-disable */

import { FC } from 'react'
import './tos.css'

const TermsOfSServicePage: FC = () => {
  return (
    <>
      <div className="c3 doc-content">
        <p className="c8">Terms of Service</p>
        <br />
        <p className="c1">
          <span className="c2">
            CLASS ACTION WAIVER, JURY WAIVER, AND ARBITRATION AGREEMENT
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            THIS AGREEMENT CONTAINS A CLASS ACTION WAIVER, JURY WAIVER, AND AN
            AGREEMENT TO RESOLVE ANY DISPUTE THAT MAY ARISE BY ARBITRATION.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Burnside Health Inc, doing business as Apneascan (&ldquo;Burnside
            Health,&rdquo; &ldquo;we,&rdquo; or &ldquo;us&rdquo;), has
            instituted this Terms of Use Agreement (&ldquo;Agreement&rdquo;) to
            regulate the access and utilization of the following: (i) the
            www.Apneascan.com website and its content, including any subdomains
            (the &ldquo;Website&rdquo;); (ii) the associated mobile application
            and its content designed for patient use (the &ldquo;App&rdquo;);
            and (iii) Burnside Health&rsquo;s FDA1-cleared software as a medical
            device technology (the &ldquo;Technology&rdquo;). The collective
            reference to the Website, App, and Technology is termed the
            &ldquo;Apneascan Offerings.&rdquo;
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            The Technology is intended for use by healthcare professionals or at
            their discretion, facilitating the evaluation of sleep disorders and
            enabling such professionals to furnish sleep data information about
            patients under their care for health and wellness assessment and
            clinical decisions. Patients under the care of healthcare
            professionals are termed &ldquo;Patients.&rdquo; Healthcare
            professionals using the Technology must possess a registered account
            and are denoted as &ldquo;Clinicians.&rdquo; &ldquo;Users&rdquo;
            collectively encompass visitors to the Website, Patients, and
            Clinicians.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            THIS CONSTITUTES A LEGAL AGREEMENT AMONG USERS AND BURNSIDE HEALTH,
            GOVERNING THEIR UTILIZATION OF THE Apneascan OFFERINGS. The
            Apneascan Offerings are not accessible to individuals below the age
            of majority. If you are under 18 years old or the age of majority in
            the relevant jurisdiction when accessing or using the Apneascan
            Offerings, or are otherwise incapable of entering into a binding
            contract, you are not authorized to use the Apneascan Offerings.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">1. ACCEPTANCE</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            By interacting with the Apneascan Offerings, all Users acknowledge
            their acceptance of the terms and conditions outlined in this
            Agreement, along with Burnside Health&rsquo;s Privacy Policy. Users
            also consent to electronic communication between Burnside Health,
            Clinicians, and Patients regarding the Apneascan Offerings. If you
            represent a legal entity accepting this Agreement for the use of the
            Technology by multiple individuals within your entity, you affirm
            that you possess the necessary rights and authority to accept this
            Agreement on behalf of your legal entity. If you do not agree to
            these terms, refrain from using the Apneascan Offerings.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            The Apneascan Offerings may involve the generation or utilization of
            computerized analyses of physiological data. Such analyses, reports,
            or graphics serve as tools for Clinicians in the determination of a
            proper diagnosis. However, it is emphasized that the Apneascan
            Offerings do not provide diagnoses or treatment for any Patient. The
            functionalities and capabilities of the Apneascan Offerings are not
            designed to replace the exercise of sound, professional judgment by
            Clinicians.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Clinicians and Patients affirm that they have thoroughly reviewed
            all warnings, recommendations, precautions, and user manuals
            provided by Burnside Health related to the Apneascan Offerings, or
            will do so before utilizing the service. Clinicians and Patients
            bear responsibility for the proper use of the Apneascan Offerings,
            adhering to intended use, following instructions from user manuals,
            and complying with all privacy policies and rules in accordance with
            U.S. laws and regulations, as well as those of their local
            jurisdiction. The Apneascan Offerings are provided solely in
            English, and Clinicians and Patients are responsible for ensuring
            their understanding of the information presented in the provided
            language, ensuring the safety of all parties involved, and
            consenting to the use of the provided language.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Burnside Health facilitates Clinicians&#39; access to information
            about the sleep data of their Patients for health and wellness
            purposes and allows Clinicians to share this information via the
            Apneascan Offerings. The FDA clearance of the Technology signifies
            its availability for purchase exclusively by licensed healthcare
            providers. Each Clinician asserts and warrants that they are a
            licensed healthcare provider authorized by law to use the Apneascan
            Offerings in their intended manner. Burnside Health is not obligated
            to, nor does it undertake measures to verify the accuracy of such
            statements. Clinicians bear sole responsibility and liability for
            any potential legal consequences arising from inaccurate statements.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">2. PRECAUTIONS</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            None of the Apneascan Offerings are intended to serve as a
            substitute for professional medical advice, diagnosis, or treatment.
            The content available on or through the Apneascan Offerings is for
            informational purposes only and is not meant to replace the
            relationship between Patients and their Clinician, physician, or
            other medical provider. Burnside Health, as a non-licensed medical
            care provider, lacks expertise in diagnosing, examining, or treating
            medical conditions or determining the impact of sleep on a
            Patient&#39;s medical condition. Patients are strongly advised to
            consult with their Clinician before making any lifestyle changes
            based on the results of an Apneascan study or if they have any
            questions regarding a medical condition.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Users must never disregard professional medical advice or delay
            seeking it due to information read on or through the Apneascan
            Offerings. In case of a medical emergency within the United States
            of America, Users should promptly call 911. Clinicians and Patients
            bear the responsibility of knowing where and how to seek medical
            emergency assistance if located in any other country. Burnside
            Health disclaims responsibility for the use of any Third-Party
            Device (as defined herein) identified to work with the Apneascan
            Offerings. Any health-related changes made as a result of using the
            Apneascan Offerings are acknowledged to be at the Clinicians&#39;
            and Patients&#39; own risk, settling any disputes between them and
            Burnside Health.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            The Apneascan Offerings may or may not include a medical device, the
            use of which is restricted under local law to Clinicians or
            individuals acting on the order of a Clinician. The Apneascan
            Offerings involve the generation or utilization of computerized
            analyses of physiologic data, acting solely as an aid for the
            Clinician in determining a proper diagnosis. They do not replace the
            Clinician, and do not diagnose or treat any Patient. The
            functionalities and capabilities of the Apneascan Offerings are
            explicitly not intended to replace the exercise of sound,
            professional judgment by Clinicians.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Users are prohibited from using the Apneascan Offerings unlawfully
            and altering any part of the Apneascan Offerings without prior
            written consent from Burnside Health.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">3. MODIFICATIONS TO THIS AGREEMENT</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Burnside Health reserves the right to make changes to the terms and
            conditions governing the Apneascan Offerings and this Agreement
            periodically. The most up-to-date version of the Agreement will be
            accessible on the Website, with the &quot;Latest Update&quot; date
            indicating the last revision of the terms and conditions. While
            Burnside Health may, at its discretion, provide a special notice on
            the Apneascan Offerings or communicate significant changes via
            email, it is not obligated to do so. Users express their acceptance
            of any modifications by continuing to use the Apneascan Offerings
            after such changes have been posted. In the event that Clinicians or
            Patients choose not to continue using the Apneascan Offerings due to
            a modification of this Agreement or the Apneascan Offerings, they
            are required to notify Burnside Health by email at
            support@Apneascan.com. Users will remain bound by the terms of the
            Agreement and Privacy Policy that were in effect as of the
            termination date.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">4. PASSWORD AND REGISTRATION</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            To utilize the Apneascan Offerings, Clinicians must create an
            account, while Patients are required to use a unique
            system-generated random patient ID along with their date of birth
            for App access. Clinicians and Patients must furnish Burnside Health
            with accurate personal and financial information and commit to
            updating such information as needed. Clinicians will choose a unique
            username and password (&quot;Clinician Login Information&quot;),
            while Patients will submit the system-generated patient ID and date
            of birth (&quot;Patient Verification Information&quot;) provided by
            their Clinician.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Maintaining the confidentiality of their respective Clinician Login
            Information and Patient Verification Information is the
            responsibility of Clinicians and Patients. They are accountable for
            all uses of this information, whether or not authorized. In case of
            security breaches or suspicions thereof, including loss, theft, or
            unauthorized disclosure, Clinicians and Patients must promptly
            notify Burnside Health through the &quot;Contact Us&quot; page on
            the Website or via email at support@Apneascan.com. Burnside Health
            disclaims any responsibility or liability for information submitted
            through unauthorized or fraudulent means or for any third
            party&#39;s fraudulent use of Clinicians&#39; or Patients&#39;
            information.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">5. INTELLECTUAL PROPERTY RIGHTS</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Data Usage:</span>
        </p>
        <p className="c1">
          <span className="c2">
            Users grant Burnside Health a perpetual, irrevocable, non-exclusive,
            worldwide, royalty-free license to use any data submitted by Users
            (&quot;Data&quot;). This license includes reproduction,
            distribution, transmission, public performance, display,
            modification, creation of derivative works, and commercial
            exploitation. Users agree not to assert any claims against Burnside
            Health or its sublicensees related to the use of the Data, provided
            Burnside Health adheres to its Privacy Policy. Users represent that
            the Data submitted will not infringe on third-party rights and will
            comply with legal requirements.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Clinician License:</span>
        </p>
        <p className="c1">
          <span className="c2">
            Subject to Clinician&#39;s acceptance of this Agreement, Burnside
            Health grants a non-exclusive, revocable, limited, personal, and
            nontransferable license to Clinicians for using the Technology and
            App. The ownership of the Technology and App remains with Burnside
            Health. Clinicians may not reproduce, distribute, modify, create
            derivative works, publicly display, or commercially exploit the
            Technology or App, except for viewing content for the benefit of
            Patients.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Patient License:</span>
        </p>
        <p className="c1">
          <span className="c2">
            Subject to Patient&#39;s acceptance of this Agreement, Burnside
            Health grants a non-exclusive, revocable, limited, personal, and
            nontransferable license to Patients for using the App. The ownership
            of the App remains with Burnside Health. Patients may not reproduce,
            distribute, modify, create derivative works, publicly display, or
            commercially exploit the App, except for personal, non-commercial
            use.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Trademarks:</span>
        </p>
        <p className="c1">
          <span className="c2">
            Trademarks such as Burnside Health, Apneascan, and others within the
            Apneascan Offerings are owned by Burnside Health or its affiliates.
            Users are prohibited from removing or altering any trademark, trade
            name, copyright, or other proprietary notice. Users may not use
            these trademarks without authorization.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Restrictions:</span>
        </p>
        <p className="c1">
          <span className="c2">
            Users are prohibited from reproducing, distributing, altering,
            merging, modifying, adapting, translating, decompiling, reverse
            engineering, disassembling, or creating derivative works based on
            the Apneascan Offerings. Users may not use the Apneascan Offerings
            to develop products with the same primary function or employ
            automated devices or processes to extract information from the
            Apneascan Offerings.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">6. INFRASTRUCTURE REQUIREMENTS</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Users acknowledge and comprehend that the utilization of the
            Apneascan Offerings necessitates the acquisition or procurement of
            certain physical and non-physical items. Patients, whether acting
            independently or through a Clinician, are required to obtain a
            third-party device for the transmission of data to the Apneascan
            Offerings. Additionally, internet access, specific software and
            hardware, Bluetooth capability, and smartphone technology are
            essential for utilizing the Apneascan Offerings. Users explicitly
            acknowledge and agree that the responsibility for meeting these
            infrastructure requirements rests with them, and they recognize the
            need for periodic updates to these requirements.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">7. DISCLAIMER</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Burnside Health assumes no responsibility for any Third-Party Device
            manufactured or supplied by entities other than Burnside Health.
            Third Party Devices are provided on an &quot;AS IS&quot; and
            &quot;WITH ALL FAULTS&quot; basis, and Burnside Health makes no
            representations or warranties regarding them. If any warranties are
            provided to Burnside Health for Third Party Devices, the company
            will make commercially reasonable efforts to pass them through to
            Clinicians or Patients, to the extent feasible.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Without limiting the foregoing, to the maximum extent permitted by
            law, except as explicitly stated herein, the Apneascan Offerings are
            provided &quot;AS IS&quot; and &quot;WITH ALL FAULTS.&quot; Burnside
            Health does not warrant the fitness, merchantability, design,
            condition, capacity, suitability, or performance of the Apneascan
            Offerings. Additionally, Burnside Health does not guarantee
            uninterrupted or error-free operation, correction of defects, or
            freedom from viruses or other harmful components. Except as
            specifically outlined herein and to the maximum extent permitted by
            law, Burnside Health expressly disclaims all warranties or
            conditions, including but not limited to warranties of
            merchantability or fitness for any particular purpose or use,
            non-infringement, and any implied warranties arising from the course
            of dealing or performance.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Clinicians have the capability to send secured links
            (&quot;Links&quot;) to Patients and other third parties for the
            sharing of study results and information. Clinicians acknowledge
            their responsibility for the acts or omissions of third parties in
            relation to the Apneascan Offerings and the use or disclosure of
            data accessible through these Links. Burnside Health explicitly
            disclaims any and all liability arising from or related to the
            sharing, use, or disclosure of Links or the data accessed through
            them, including unauthorized use or disclosure of Protected Health
            Information (PHI). Upon Burnside Health&#39;s request, Clinicians
            agree to indemnify and hold harmless Burnside Health and its
            members, managers, officers, employees, subsidiaries,
            representatives, agents, licensors, and affiliates from any claims,
            losses, damages, or demands, including reasonable attorneys&#39;
            fees, made by any third party due to or arising out of the
            Clinician&#39;s provision of Links to any third party or the use or
            disclosure of information made available through the Links.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">8. SUPPORT</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Burnside Health will exert reasonable efforts to provide technical
            support for the Apneascan Offerings unless the company decides to
            discontinue such offerings. Technical support may be offered through
            various means, including the Internet, telephone, or other methods,
            as determined by Burnside Health at its sole discretion. Users,
            including Clinicians or Patients, in need of support should contact
            Burnside Health through the &quot;Contact Us&quot; page on the
            website or via email at support@Apneascan.com. Response times may
            vary.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Burnside Health retains the right to make changes to, suspend, or
            permanently discontinue the Apneascan Offerings. Users agree that
            Burnside Health will not be held liable to Users or any third party
            for any such modifications, suspension, or discontinuation.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">9. LIMITATION OF LIABILITY</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            In no event shall any user be entitled to, and Burnside Health, its
            members, managers, officers, employees, subsidiaries,
            representatives, agents, licensors, and affiliates will not be
            liable for, any indirect, special, incidental, consequential, or
            punitive damages of any nature arising out of, relating to, or in
            connection with the use of or inability to use the Apneascan
            Offerings. This includes, without limitation, loss of data, computer
            failure or malfunction, loss of customers, interruptions of service
            (including, without limitation, ISP disruptions), software or
            hardware failures, or any other event that may result in a loss of
            data or disruption of service. Even if Burnside Health has been
            advised of the possibility of such damages, users agree that they
            will not bring a cause of action against Burnside Health&#39;s
            members, managers, officers, or employees in their personal
            capacity.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            The recovery from Burnside Health for any claim or set of claims
            shall not exceed, in the aggregate, the specific fees paid by users
            to Burnside Health for the Apneascan Offering in the twelve months
            preceding the applicable incident out of which the liability arose,
            irrespective of the nature of the claim, whether in contract, tort,
            warranty, or otherwise. The negation and limitation of damages set
            forth above are fundamental elements of the basis of the bargain
            between us and any user of the Apneascan Offerings. The Apneascan
            Offerings would not be provided without such limitations.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Some jurisdictions do not allow the limitation of incidental or
            consequential damages, so such limitation shall apply to users to
            the maximum extent allowed under such jurisdictions.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">10. INDEMNITY</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Upon Burnside Health&rsquo;s request, all users of the Apneascan
            Offerings agree to indemnify and hold harmless Burnside Health and
            its members, managers, officers, employees, subsidiaries,
            representatives, agents, licensors, and affiliates from and against
            any claim, loss, damage, or demand, including reasonable
            attorneys&rsquo; fees, made by any third party. This indemnification
            is due to or arises out of the user&#39;s use of the Apneascan
            Offerings, breach of this Agreement by the user (or anyone using
            Clinician Login Information or Patient Verification Information
            received other than as a result of Burnside Health&rsquo;s
            negligence or willful misconduct), or violation of any law or the
            rights of a third party by the user (or anyone using Clinician Login
            Information or Patient Verification Information received other than
            as a result of Burnside Health&rsquo;s negligence or willful
            misconduct).
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">11. FORCE MAJEURE</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Burnside Health shall not be liable for any delay or failure to
            perform resulting from causes outside the reasonable control of
            Burnside Health, including, without limitation, acts of God,
            pandemics, epidemics, war, terrorism, riots, embargoes, acts of
            civil or military authorities, fire, floods, accidents, strikes, or
            shortages of transportation facilities, fuel, energy, labor, or
            materials.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">12. PRIVACY POLICY</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Burnside Health has established a Privacy Policy that describes how
            Burnside Health collects and uses various types of information. The
            Privacy Policy is available at
            [www.Apneascan.com](www.Apneascan.com). By using the Apneascan
            Offerings, all users consent to the privacy practices detailed in
            the Privacy Policy, which may be modified from time to time as set
            out in the Privacy Policy. To the extent users are covered by laws
            requiring their consent for data transfer to the United States, such
            users agree that their data may be transferred to the United States
            and collected and used in accordance with the Privacy Policy.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">13. Business Associate Agreement</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Burnside Health may serve as a business associate, as defined by
            HIPAA, for Clinicians who qualify as covered entities under HIPAA
            (referred to as &quot;covered entity&quot; in Section 13). This
            specific section, in conjunction with Burnside Health&#39;s Privacy
            Policy, governs Burnside Health&#39;s handling of Protected Health
            Information (&quot;PHI&quot;) and outlines the responsibilities of
            both the covered entity and Burnside Health concerning PHI. Section
            13 remains in effect even after the termination of this Agreement
            for as long as Burnside Health is involved in creating, receiving,
            transmitting, or maintaining PHI. In case of any conflict between
            Section 13 and any other agreement or provision between a covered
            entity and Burnside Health, Section 13 takes precedence. Unless
            explicitly stated otherwise in this section, all capitalized terms
            within Section 13 carry the meanings defined in the Agreement or in
            the Health Insurance Portability and Accountability Act of 1996, as
            amended by the Health Information Technology for Economic and
            Clinical Health Act, and their implementing regulations,
            collectively referred to as &quot;HIPAA.&quot;
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Burnside Health as Business Associate</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Section 13 applies exclusively when Burnside Health functions as a
            business associate to a covered entity. If Burnside Health is not
            operating in the capacity of a business associate, it carries no
            obligations under this Section 13.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Use and Disclosure of Protected Health Information (PHI)
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Burnside Health may Use or Disclose PHI to provide services for
            covered entities as specified in the Agreement. The Use or further
            Disclosure of PHI will not violate the requirements of the HIPAA
            privacy rule if done by a covered entity. However, Burnside Health
            may Use and Disclose PHI as necessary for its proper management and
            administration or to fulfill legal obligations. Any Use or further
            Disclosure of PHI will be in accordance with this Section 13 or as
            Required by Law. Burnside Health has the right to de-identify PHI
            following HIPAA requirements, after which it will no longer be
            considered PHI or subject to the terms of this Section 13.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Safeguards</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Burnside Health commits to using appropriate safeguards and
            complying, where applicable, with Subpart C of 45 CFR Part 164
            concerning electronic PHI to prevent the Use or Disclosure of PHI
            beyond what is permitted by this Section 13.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Notice of Impermissible Uses and Disclosures
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Burnside Health will promptly notify the covered entity of any
            unauthorized Use or Disclosure of PHI, including any Breaches of
            Unsecured PHI.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Notice of Security Incidents</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Burnside Health will notify the covered entity quarterly of any
            successful Security Incidents. Both parties acknowledge and agree
            that no further notice will be provided regarding the ongoing
            existence and occurrences or attempts of Unsuccessful Security
            Incidents for which additional notice is not required.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Subcontractors</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Burnside Health ensures that any subcontractor handling PHI on its
            behalf will agree to substantially similar restrictions and
            conditions as outlined in this Section 13.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Access to PHI</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Burnside Health will make PHI in a Designated Record Set available
            to the covered entity as necessary for the covered entity to fulfill
            its obligations under 45 CFR 164.524.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Amendment to PHI</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Burnside Health will make any amendments to PHI in a Designated
            Record Set as directed or agreed to by the covered entity pursuant
            to 45 C.F.R. &sect; 164.526 or take other measures necessary for the
            covered entity to satisfy its obligations under 45 CFR 164.526.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Accounting of Disclosures</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Burnside Health will maintain and provide the information required
            to produce an accounting of disclosures to the covered entity as
            necessary for the covered entity to fulfill its obligations under 45
            CFR 164.528.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Internal Records</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Burnside Health will make its internal practices, books, and records
            relating to the Use and Disclosure of PHI available to the Secretary
            of the U.S. Department of Health and Human Services for the purpose
            of determining the covered entity&#39;s compliance with HIPAA.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Covered Entity Obligations</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            The covered entity is responsible for implementing privacy and
            security safeguards to protect PHI. The covered entity warrants that
            it has obtained any necessary authorizations, consents, and other
            permissions required under applicable law before transmitting any
            PHI to Burnside Health. The covered entity will not request or cause
            Burnside Health to make any Use or Disclosure of PHI in a manner
            that does not comply with HIPAA or this Section 13.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Effect of Termination</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Upon termination of the relationship between the covered entity and
            Burnside Health, Burnside Health will, if feasible, return or
            destroy all PHI it maintains in any form. If return or destruction
            is not feasible, Burnside Health may maintain one (1) copy of such
            information for its internal purposes and legal obligations, in its
            sole discretion, in accordance with this Section 13.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">14. Third-Party Content</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            The Apneascan Offerings may contain links or provide access to other
            websites and resources. Burnside Health does not have control over
            these sites and resources, and their availability is beyond Burnside
            Health&#39;s responsibility. Burnside Health neither endorses nor is
            responsible or liable for any content, advertising, products, or
            other materials on or available from such sites or resources.
            Additionally, Burnside Health shall not be held responsible or
            liable, directly or indirectly, for any damage or loss caused or
            alleged to be caused by or in connection with the use or purchase
            of, or reliance on, any such content, goods, or services available
            on or through any such site or resource.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">15. Legal Compliance</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Users agree to adhere to all applicable domestic and international
            laws, statutes, ordinances, and regulations concerning their use of
            the Apneascan Offerings provided herein.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">16. Unlawful Activity</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Burnside Health reserves the right to investigate complaints or
            reported violations of the Agreement and to take any necessary
            action, including reporting suspected unlawful activity to law
            enforcement officials, regulators, or other third parties. Burnside
            Health may disclose any information deemed necessary or appropriate
            to such persons or entities, including but not limited to profile
            details, email addresses, usage history, posted materials, IP
            addresses, and traffic information.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">17. Export</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            The Apneascan Offerings and all related technical data are subject
            to U.S. export control laws (&quot;Export Control Laws&quot;). These
            offerings may not be exported to countries embargoed by the U.S. or
            to individuals on the U.S. Treasury Department&#39;s list of
            Specially Designated Nationals or the U.S. Commerce
            Department&rsquo;s Table of Denial Orders. Clinicians and Patients
            warrant that they are not located in, under the control of, or a
            national or resident of any such country or on any such list.
            Clinicians and Patients further warrant that (a) they shall not
            export, re-export, or otherwise use the Apneascan Offerings in
            violation of Export Control Laws or any other applicable law, and
            (b) they will be responsible for obtaining any necessary U.S.
            government authorization required to ensure compliance with Export
            Control Laws. Clinicians and Patients may contact the U.S.
            Department of Commerce, State, and Treasury for guidance on
            applicable licensing requirements and other restrictions.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">18. Term and Termination</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Burnside Health reserves the right to terminate the License and/or
            the right to access the Apneascan Offerings at its discretion, with
            or without cause. Users acknowledge that Burnside Health, at its
            sole discretion, may terminate, remove, or alter the Apneascan
            Offerings at any time and for any reason or no reason.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Users have the option to cancel their license to any or all of the
            Apneascan Offerings by notifying Burnside Health through email at
            support@Apneascan.com. Such cancellation will be effective thirty
            (30) calendar days after the notification. Regardless of the reason
            for termination, Users will remain bound by the Agreement and
            Privacy Policy that were in effect as of the termination date.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            In the event of termination or cancellation, Burnside Health does
            not provide refunds or credits for any partial, monthly, or annual
            payments related to the Apneascan Offerings.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">19. Assignment</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Users shall not assign, transfer, pledge, or otherwise transfer this
            Agreement without Burnside Health&#39;s prior written consent.
            Burnside Health retains the right to assign or novate this
            Agreement, delegate, and/or subcontract its duties, in whole or in
            part, without notice. Any attempted assignment contrary to this
            Agreement shall be void.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            20. Arbitration, Jury Waiver, and Class Action Waiver
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Please carefully read the following ARBITRATION AGREEMENT, as it
            requires Users to arbitrate certain disputes and claims with
            Burnside Health and limits the manner in which Users can seek
            relief.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            - General: The parties will attempt to resolve any dispute through
            good-faith negotiation. If negotiations do not lead to a resolution
            within thirty (30) business days, any dispute arising out of or
            relating to this Agreement shall be determined by arbitration
            administered by the International Centre for Dispute Resolution in
            accordance with its International Arbitration Rules. The arbitration
            will involve one arbitrator and will take place in the city and
            country where Burnside Health&#39;s headquarters is located. If this
            location is not feasible, the arbitration shall occur in a location
            of Burnside Health&rsquo;s choosing. The language used in the
            arbitral proceedings will be English, and the award rendered by the
            arbitrator may be entered in any court with jurisdiction.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Waiver of Jury Trial:</span>
        </p>
        <p className="c1">
          <span className="c2">
            Users and Burnside Health waive any constitutional and statutory
            rights to go to court and have a trial in front of a judge or jury.
            Instead, they choose to have claims and disputes resolved by
            arbitration, which is typically more limited, efficient, and less
            costly than court proceedings.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Waiver of Class or Consolidated Actions:</span>
        </p>
        <p className="c1">
          <span className="c2">
            Users and Burnside Health agree that each may bring claims against
            the other only in their individual capacity and not as a plaintiff
            or class member in any purported class or representative action. If
            the waiver of class or consolidated actions is deemed invalid or
            unenforceable, neither party is entitled to arbitration, and all
            claims and disputes will be resolved pursuant to the Exclusive Venue
            section of this Section 20.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Opt-out:</span>
        </p>
        <p className="c1">
          <span className="c2">
            Users have the right to opt out of the arbitration provisions within
            thirty (30) calendar days of first accepting this Agreement. To opt
            out, Users must send a written notice, including their name,
            residence address, email address and/or telephone number associated
            with their account, and a clear statement expressing their decision
            to opt out.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Exclusive Venue:</span>
        </p>
        <p className="c1">
          <span className="c2">
            If a User sends an opt-out notice or in circumstances allowing
            litigation, Users and Burnside Health agree that any judicial
            proceeding will be brought in the state or federal courts located in
            Clark County, Washington State, USA.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Governing Law:</span>
        </p>
        <p className="c1">
          <span className="c2">
            This Agreement shall be governed by the laws of the State of
            Colorado without regard to conflicts or choice of law rules or
            principles.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Survival:</span>
        </p>
        <p className="c1">
          <span className="c2">
            This arbitration agreement will survive the termination of a
            User&rsquo;s relationship with Burnside Health.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">21. Waivers:</span>
        </p>
        <p className="c1">
          <span className="c2">
            The failure of Burnside Health to enforce any provisions of this
            Agreement or to require User&rsquo;s performance at any time shall
            not be construed as a waiver. Burnside Health retains the right to
            enforce each provision in accordance with the terms of this
            Agreement.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">22. Entire Agreement:</span>
        </p>
        <p className="c1">
          <span className="c2">
            This Agreement and the referenced documents constitute the
            comprehensive agreement between the parties regarding the subject
            matter and override all previous or contemporaneous agreements,
            whether oral or written, pertaining to such subject matter.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">23. Severability:</span>
        </p>
        <p className="c1">
          <span className="c2">
            If any provision(s) of the Agreement is found by a court of
            competent jurisdiction to be contrary to law, such provision(s) will
            be construed, as nearly as possible and to the maximum extent
            permissible, to reflect the intentions of the parties, with the
            other provisions remaining in full force and effect.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">24. Contact Information:</span>
        </p>
        <p className="c1">
          <span className="c2">
            All inquiries regarding this Agreement should be directed to:
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">Burnside Health Customer Service &nbsp;</span>
        </p>
        <p className="c1">
          <span className="c2">12405 SE 2nd Cir, &nbsp;</span>
        </p>
        <p className="c1">
          <span className="c2">Vancouver, WA, 98684, USA &nbsp;</span>
        </p>
        <p className="c1">
          <span className="c2">Email: support@Apneascan.com &nbsp;</span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c1">
          <span className="c2">
            Apneascan&reg; is a registered trademark of Burnside Health Inc.
          </span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
        <p className="c0">
          <span className="c2"></span>
        </p>
      </div>
    </>
  )
}

export { TermsOfSServicePage }
