import { FC } from 'react'
import { Radio, RadioProps } from 'antd'
import { styled } from 'styled-components'
import { colors } from '../../../theme'

interface IRadio extends RadioProps {
  label?: string
}

const StyledRadio = styled(Radio)`
  & .ant-radio-checked {
    & .ant-radio-inner {
      border-color: ${colors.white};
      background-color: ${colors.blue};
    }
  }
  & span:nth-child(2) {
    color: ${colors.white};
    font-size: 16px;
    font-weight: 500;
  }
`

const RadioComponent: FC<IRadio> = ({ className, label, value, ...rest }) => {
  return (
    <StyledRadio value={value} className={className} {...rest}>
      {label}
    </StyledRadio>
  )
}

export { RadioComponent as Radio }
