import { FC } from 'react'
import styled from 'styled-components'
import { Text } from '../../atom'

const Wrapper = styled.section`
  margin-top: 1rem;
  & .content {
    padding: 2rem 1rem;
    & .desp {
      text-align: center;
    }
    & .feature-wrapper {
      display: flex;
      justify-content: space-around;
      gap: 20px;
      & .feature {
        text-align: center;
        & .label {
          margin-top: 1rem;
        }
      }
    }
  }
  @media (width > 768px) {
    margin-top: 4rem;
    & .content {
      max-width: 1100px;
      margin: 0 auto;
      & .desp {
        font-size: 24px !important;
      }
      & .feature-wrapper {
        margin-top: 4rem;
        & .feature {
          text-align: center;
          & .label {
          }
        }
      }
    }
  }
`

const FeatureSection: FC = () => {
  return (
    <Wrapper>
      <div className="content">
        <Text
          content="ApneaScan is a Software As a Medical Device (Samd) to diagnose OSA with 90% sensitivity compared to in-lab polysomnography"
          className="desp"
          preset="bodyLargeMedium"
        />
        <div className="feature-wrapper">
          <div className="feature">
            <img
              src="/assets/imgs/checked.png"
              alt="checked"
              width={70}
              height="auto"
            />
            <Text
              content="Affordable"
              preset="headingSmall"
              className="label"
            />
          </div>
          <div className="feature">
            <img
              src="/assets/imgs/checked.png"
              alt="checked"
              width={70}
              height="auto"
            />
            <Text
              content="Evidence Based"
              preset="headingSmall"
              className="label"
            />
          </div>
          <div className="feature">
            <img
              src="/assets/imgs/checked.png"
              alt="checked"
              width={70}
              height="auto"
            />
            <Text content="Intuitive" preset="headingSmall" className="label" />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export { FeatureSection }
