import React from 'react'
import styled from 'styled-components'

interface DividerProps {
  width?: string
  height?: string
  background?: string
  marginTop?: string
  marginBottom?: string
  marginLeft?: string
  marginRight?: string
  borderRadius?: string
  className?: string
}

const StyledDiv = styled.div<{
  $width: DividerProps['width']
  $height: DividerProps['height']
  $marginTop: DividerProps['marginTop']
  $marginBottom: DividerProps['marginBottom']
  $marginLeft: DividerProps['marginLeft']
  $marginRight: DividerProps['marginRight']
  $borderRadius: DividerProps['borderRadius']
  $background: DividerProps['background']
}>`
  width: ${({ $width }) => $width && $width};
  height: ${({ $height }) => $height && $height};
  background: ${({ $background }) =>
    $background ? $background : 'transparent'};
  margin-top: ${({ $marginTop }) => $marginTop || '0'};
  margin-bottom: ${({ $marginBottom }) => $marginBottom || '0'};
  margin-left: ${({ $marginLeft }) => $marginLeft || '0'};
  margin-left: ${({ $marginRight }) => $marginRight || '0'};
  border-radius: ${({ $borderRadius }) => $borderRadius || '0'};
`

export const Divider: React.FC<DividerProps> = ({
  width,
  height,
  background,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  borderRadius,
  ...rest
}) => {
  return (
    <StyledDiv
      $width={width}
      $height={height}
      $marginTop={marginTop}
      $marginBottom={marginBottom}
      $marginLeft={marginLeft}
      $marginRight={marginRight}
      $background={background}
      $borderRadius={borderRadius}
      {...rest}
    />
  )
}
