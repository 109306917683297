import { FC, ReactNode } from 'react'
import { Button, ButtonProps } from 'antd'
import styled, { css } from 'styled-components'
import { colors, spacing } from '../../../theme'
import { Text } from '../Text'

interface IButtonProps extends ButtonProps {
  variant?: 'primary' | 'basic' | 'link'
  label?: string
  padding?: string
  height?: string
  minWidth?: string
  width?: string
  background?: string
  color?: string
  fontSize?: string
  fontWeight?: string
  children?: ReactNode
  htmlType?: 'button' | 'submit' | 'reset' | undefined
  href?: string
  disabled?: boolean
  icon?: ReactNode
  loading?: boolean | { delay?: number }
  borderRadius?: string
  RightAccessory?: ReactNode
}

type StyledButtonProps = Pick<
  IButtonProps,
  | 'height'
  | 'padding'
  | 'width'
  | 'fontWeight'
  | 'variant'
  | 'background'
  | 'fontSize'
> & { $minWidth?: string; $borderRadius: string }

const variantStyles = (
  variant: IButtonProps['variant'] = 'primary',
  background?: string,
  color?: string,
) =>
  ({
    primary: css`
      background: ${background || colors.blue};
      & div {
        color: ${color || colors.white} !important;
      }
      &:hover {
        background: ${background || colors.blue} !important;
        & div {
          color: ${colors.white} !important;
        }
      }
    `,

    basic: css`
      background: ${background || colors.white};
      border: 1px solid ${colors.blue};
      & div {
        color: ${colors.blue} !important;
      }
      &:hover {
        background: ${colors.white} !important;
        & div {
          color: ${colors.blue} !important;
        }
      }
    `,

    link: css`
      background: transparent;
      & div {
        color: ${colors.black} !important;
      }
      &:hover {
        background: transparent !important;
        & div {
          color: ${colors.blue} !important;
        }
      }
    `,
  })[variant]

const shapeStyles = (
  shape: IButtonProps['shape'] = 'default',
  width?: IButtonProps['width'],
) =>
  ({
    circle: css`
      width: ${width ?? '42px'};
    `,

    round: css``,
  })[shape]

const StyledButton = styled(Button)<StyledButtonProps>`
  padding: ${({ padding }) => padding || '.5em 1.5em'};
  height: ${({ height }) => height || '42px'};
  min-width: ${({ $minWidth }) => $minWidth || '100px'};
  width: ${({ width }) => width && width};
  font-weight: ${({ fontWeight }) => fontWeight || '700'};
  border-radius: ${({ $borderRadius }) => $borderRadius || '.5rem'};
  box-shadow: none;
  ${({ variant, background, color }) =>
    variantStyles(variant, background, color)}
  ${({ shape, width }) => shapeStyles(shape, width)}
  &:hover {
    opacity: 0.8;
  }
  & p {
    display: inline;
    font-size: ${({ fontSize }) =>
      fontSize || `${spacing.medium}px !important`};
  }
  &:disabled {
    border: 1px solid ${colors.grey} !important;
  }
`

const ButtonComponent: FC<IButtonProps> = ({
  label,
  disabled,
  icon,
  loading,
  htmlType,
  href,
  variant = 'primary',
  children,
  minWidth,
  type = 'primary',
  shape = 'default',
  RightAccessory,
  width,
  background,
  color,
  borderRadius,
  ...rest
}) => {
  return (
    <StyledButton
      type={type}
      variant={variant}
      disabled={disabled}
      href={href}
      htmlType={htmlType}
      icon={icon}
      width={width}
      color={color}
      shape={shape}
      loading={loading}
      $minWidth={minWidth!}
      background={background}
      $borderRadius={borderRadius!}
      {...rest}
    >
      {label ? <Text content={label} /> : children}
      {RightAccessory && RightAccessory}
    </StyledButton>
  )
}

export { ButtonComponent as Button }
