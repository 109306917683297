import { FC, useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { useSnapshot } from 'valtio'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { modalStore } from '../../../../store'
import { ERROR_MESSAGES, ROLE_ACTIONS } from '../../../../constants'
import { userGroupService } from '../../../../services'
import { colors } from 'src/theme'
import { IPermission, IRoleActions } from 'src/interfaces'
import { useRoles, useUserGroupPermissions } from 'src/queries'
import { CheckBox, Divider, Loader, ModalBase } from 'src/components'
import { getRole, setRole } from 'src/utils'

const Main = styled.div`
  padding: 0 5px;
  padding-bottom: 30px;
`

const Info = styled.p`
  font-size: 13px;
  margin-top: 10px;
  color: ${colors.red};
`

const Role = styled.div`
  margin-top: 15px;
  & .title {
    font-size: 15px;
    font-weight: 500;
  }
`

const FieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  & > div:nth-child(2n) {
    margin-left: 30px;
  }
  & .title-info {
    color: ${colors.red};
    font-size: 12px;
    padding-left: 5px;
  }
`

interface TestDataTypes {
  roleID?: string
  permissions?: any
}

export const RoleAssignmentModal: FC = () => {
  const { shouldOpen } = useSnapshot(modalStore.roleAssignment)
  const [adminRoles, setAdminRoles] = useState<
    Array<IPermission & TestDataTypes>
  >([])
  const id = 'fsdfsdf'

  /**
   * Fetch all roles
   */
  const {
    data: rolesData,
    isLoading: rolesLoading,
    isFetching: rolesFetching,
  } = useRoles({ enabled: shouldOpen })

  /**
   * Fetch roles assigned to current user group
   */
  const {
    isLoading,
    data: adminRolesData,
    isFetching,
  } = useUserGroupPermissions({ enabled: shouldOpen, id })

  useEffect(() => {
    adminRolesData && setAdminRoles(adminRolesData)
  }, [adminRolesData])

  const mutation = useMutation({
    mutationKey: ['createUserGroupPermissions'],
    mutationFn: userGroupService.addUserGroupPermission,
    onSuccess: () => {
      toast.success('Roles assigned to user group successfully')
    },
    onError: err => {
      toast.error(err?.message || ERROR_MESSAGES.ERROR_OCCURED)
    },
  })

  const handleChange = (
    e: CheckboxChangeEvent | CheckboxValueType[] | boolean,
    roleID: string,
  ) => {
    const roleData = {
      roleID,
      permissions: setRole(e as IRoleActions[]),
    }

    if (adminRoles.find(admin => admin.roleID === roleID)) {
      setAdminRoles(prevDatas => {
        return prevDatas.map(prevData => {
          return {
            ...prevData,
            permissions:
              prevData.roleID === roleID
                ? roleData.permissions
                : prevData.permissions,
          }
        })
      })
    } else {
      setAdminRoles(prevData => [...prevData, roleData])
    }
  }

  const getCheckBoxValue = (roleID: string) => {
    const permissions = adminRoles?.filter(role => role.roleID === roleID)?.[0]
      ?.permissions
    const checkBoxValue = getRole(permissions)
    return checkBoxValue
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (id && adminRoles.length) {
      const values = { adminId: id, adminPermissions: adminRoles }
      mutation.mutate(values)
    }
  }

  return (
    <ModalBase
      title="Assign Role"
      open={shouldOpen}
      onOk={handleSubmit}
      width="800px"
      cancelButtonProps={{
        style: {
          marginLeft: 12,
          marginBottom: 8,
        },
      }}
      okButtonProps={{
        loading: mutation.isPending,
        disabled: mutation.isPending,
        style: {
          marginRight: 12,
          width: 300,
        },
      }}
      onCancel={() => {
        modalStore.roleAssignment.close()
      }}
      okText="Yes, Proceed"
    >
      {rolesLoading || isLoading || rolesFetching || isFetching ? (
        <Loader />
      ) : (
        <Main>
          <Info>
            * You must assign READ operation before assigning other operations
          </Info>
          <form onSubmit={handleSubmit}>
            <FieldsWrapper>
              {rolesData?.map(role => {
                return (
                  <Role key={`role-${role?.id}`}>
                    <div className="title">
                      {role.name}
                      {role.slug === 'super_admin' && (
                        <span className="title-info">
                          *This role allow access to all other roles*
                        </span>
                      )}
                    </div>
                    <Divider height="5px" />
                    <CheckBox
                      value={getCheckBoxValue(role.id!)}
                      type="group"
                      options={ROLE_ACTIONS}
                      onChange={e => handleChange(e, role.id!)}
                    />
                  </Role>
                )
              })}
            </FieldsWrapper>
          </form>
        </Main>
      )}
    </ModalBase>
  )
}
