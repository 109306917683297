import {
  MutationFunction,
  QueryFunction,
  QueryKey,
} from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { IClinic, IOrganization } from 'src/interfaces'
import { API } from 'src/lib'

interface IQueryKey {
  queryKey?: QueryKey[]
}

const fetchOrganisations: QueryFunction<IOrganization[], IQueryKey[]> = async ({
  queryKey,
}) => {
  const keyword = queryKey?.[3] as QueryKey
  const page = queryKey?.[1] as QueryKey
  const pageSize = queryKey?.[2] as QueryKey

  const { data } = await API.get(`/organization/all`, {
    params: {
      page,
      pageSize,
      keyword,
    },
  })
  return data
}

const addOrganisation: MutationFunction<
  { data: string },
  IOrganization
> = async reqPayload => {
  return API.post('/organization/', reqPayload)
}

const enableOrganisation: MutationFunction<
  AxiosResponse,
  { id: string }
> = data => {
  const id = data?.id
  return API.put(`/organization/${id}/enable`)
}

const disableOrganisation: MutationFunction<
  AxiosResponse,
  { id: string }
> = data => {
  const id = data?.id
  return API.put(`/organization/${id}/disable`)
}

const fetchOrganisationsClinic: QueryFunction<IClinic[], IQueryKey[]> = async ({
  queryKey,
}) => {
  const keyword = queryKey?.[3] as QueryKey
  const id = queryKey?.[4] as QueryKey
  const page = queryKey?.[1] as QueryKey
  const pageSize = queryKey?.[2] as QueryKey

  const { data } = await API.get(`/organization/${id}/clinics`, {
    params: {
      page,
      pageSize,
      keyword,
    },
  })
  return data
}

export const organisationService = {
  addOrganisation,
  fetchOrganisations,
  enableOrganisation,
  fetchOrganisationsClinic,
  disableOrganisation,
}
