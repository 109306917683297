import React, { ReactNode } from 'react'
import { Select as AntSelect } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import styled from 'styled-components'
import { CaretDownOutlined, LoadingOutlined } from '@ant-design/icons'
import { Text } from '../Text'
import { colors } from '../../../theme'

const { Option } = AntSelect

interface SelectOption {
  value: string | boolean | null | undefined | number
  name: string
}

interface SelectProps {
  value?: string | number | null | boolean | string[]
  label?: string
  placeholder?: string
  name?: string
  error?: string | boolean
  id?: string
  width?: string
  height?: string
  loading?: boolean
  allowClear?: boolean
  showSearch?: boolean
  size?: SizeType
  suffixIcon?: ReactNode
  options?: SelectOption[]
  onChange?: (val) => void
  onSearch?: (val?: string) => void
  onBlur?: React.FocusEventHandler<HTMLElement>
  required?: boolean
  className?: string
  disabled?: boolean
  filterOption?: boolean
  mode?: 'multiple' | 'tags'
}

const SelectWrapper = styled.div<SelectProps>`
  & > .ant-select {
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '100%'};
    & .ant-select-selector {
      height: ${({ height }: SelectProps) => {
        return height ? height : `40px`
      }};
      border-radius: 0.5rem;
      border: ${({ error }: SelectProps) => {
        return error ? `1px solid ${colors.red}` : `1px solid ${colors.grey}`
      }};
      background: ${colors.white};

      color: ${colors.darkGrey2};
      & .ant-select-selection-item {
        font-size: 14px;
        text-align: left;
        padding-left: 4px;
        color: ${colors.darkBlue};
      }
      & .ant-select-selection-placeholder {
        padding-left: 10px;
        line-height: 18px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: ${colors.darkGrey1};
      }
      & .ant-select-selection-search {
        input {
          height: ${({ height }: SelectProps) => {
            return height ? height : `8px`
          }};
          text-indent: 4px;
        }
      }
    }

    & .ant-select-arrow {
      .anticon {
        color: ${colors.grey};
      }
    }

    & .ant-select-clear {
      border-radius: 50%;
      & span {
        & svg {
          fill: ${colors.grey} !important;
        }
      }
    }

    &:hover,
    &:focus,
    &:focus-within {
      & .ant-select-selector {
        border: 1px solid ${colors.grey} !important;
      }
    }
  }

  & > .ant-select-multiple {
    & .ant-select-selector {
      height: auto;
      min-height: 40px;
      flex-wrap: nowrap;
      & .ant-select-selection-overflow {
        margin-left: 8px;
        & .ant-select-selection-overflow-item {
          align-self: center;
          & .ant-select-selection-item {
            margin-top: 0;
            border-radius: 24px;
            height: 32px;
            border: 1px solid ${colors.white};
            & .ant-select-selection-item-content {
              margin-top: 4px;
            }
            & .ant-select-selection-item-remove {
              & .anticon {
                color: ${colors.green};
              }
            }
          }
        }
      }
    }
  }

  & .ant-select-dropdown {
    background: ${colors.darkGrey2};
    & * {
      font-size: 14px;
      color: ${colors.white};
    }
    & .ant-select-item-option-selected {
      background: ${colors.darkGrey2};
    }
  }
`

const Label = styled.div`
  margin-bottom: 5px;
  margin-right: 6px;
  text-align: left;
  color: ${colors.blackConst};
  font-size: 14px !important;
  display: flex;
  & > span {
    margin-left: 0.25rem;
    color: ${colors.red};
  }
`

const Error = styled(Text)`
  color: ${colors.red};
  margin-bottom: 0;
  margin-top: 2px;
  text-align: left;
  margin-left: 2px;
`

const mapOptions = (option: SelectOption) => {
  return (
    <Option key={`${option.name}-${option.value}`} value={option.value}>
      {option.name}
    </Option>
  )
}

const Select: React.FC<SelectProps> = ({
  options,
  label,
  onChange,
  onSearch,
  error,
  value,
  loading = false,
  width,
  showSearch = false,
  allowClear = true,
  required,
  onBlur,
  className,
  filterOption,
  disabled = false,
  ...rest
}) => {
  return (
    <SelectWrapper width={width!} className={className} error={error} {...rest}>
      {label && (
        <Label>
          <Text content={label} preset="bodyLargeMedium" />
          {required && <span>*</span>}
        </Label>
      )}
      <AntSelect
        value={value}
        getPopupContainer={trigger => trigger.parentNode}
        onChange={onChange}
        onSearch={onSearch}
        showSearch={showSearch}
        allowClear={allowClear}
        onBlur={onBlur}
        filterOption={filterOption}
        disabled={disabled}
        suffixIcon={loading ? <LoadingOutlined /> : <CaretDownOutlined />}
        loading={loading}
        {...rest}
      >
        {options && options?.map(mapOptions)}
      </AntSelect>
      {error && (
        <Error preset="bodyMediumRegular" content={error as string}></Error>
      )}
    </SelectWrapper>
  )
}

export { Select }
