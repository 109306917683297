import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from 'src/constants'
import { IRole } from 'src/interfaces'
import { roleService } from 'src/services'

/**
 * useRoles
 *
 * @param enabled => query enable/disabled boolean
 * @returns useQuery response
 */
export const useRoles = ({ enabled }: { enabled: boolean }) => {
  return useQuery({
    queryKey: [QUERY_KEYS.FETCH_ROLES],
    queryFn: roleService.fetchRoles,
    enabled: enabled,
    refetchOnWindowFocus: false,
    retry: 1,
    select: response =>
      response?.map((role: IRole) => {
        return {
          key: role?.id,
          id: role?.id,
          name: role?.name,
          value: role?.id,
          slug: role?.slug,
          createdAt: role?.createdAt,
        }
      }),
  })
}
