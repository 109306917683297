import { FC } from 'react'
import styled from 'styled-components'
import { Text } from '../../atom'

const MainWrapper = styled.section`
  background-color: #def2fc;
  margin-top: 2rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  & .container {
    & .header {
      text-align: center;
      color: #141464;
    }
    & .card-wrappers {
      width: 90%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      gap: 1rem;
      & .each-card {
        text-align: center;
        background: linear-gradient(to right, #be93c5, #7bc6cc);
        border-radius: 1.5rem;
        padding: 0.75rem;
        height: 100%;
        max-width: 400px;
        &:nth-child(2) {
          background: linear-gradient(to bottom, #abbaab, #ffffff);
        }
        & .desp {
          margin-top: 1rem;
          font-size: 1.25rem !important;
        }
      }
    }
  }

  @media (width > 768px) {
    padding-top: 6rem;
    padding-bottom: 6rem;
    & .container {
      & .header {
      }
      & .card-wrappers {
        margin-top: 4rem;
        & .each-card {
          flex: 1;
          & .desp {
            font-size: 1.1rem !important;
          }
        }
      }
    }
  }
`

const TechnologySection: FC = () => {
  return (
    <MainWrapper>
      <div className="container">
        <Text
          preset="headinglarge"
          content="Technology behind Apneascan"
          className="header"
        />
        <div className="card-wrappers">
          <div className="each-card">
            <img
              src="/assets/imgs/data-collection.png"
              alt="data-collection"
              width={150}
              height="auto"
            />
            <Text
              className="desp"
              preset="bodyLargeMedium"
              content="ApneaScan correlates DAP (amplitude fluctuation of the PPG signal) with oxygen desaturation to accurately estimate the severity of obstructive sleep apnea"
            />
          </div>
          <div className="each-card">
            <img
              src="/assets/imgs/data-transfer.png"
              alt="data-transfer"
              width={150}
              height="auto"
            />
            <Text
              className="desp"
              preset="bodyLargeMedium"
              content="ApneaScan records this data non-invasively and transfers them wirelessly via  a mobile device"
            />
          </div>
          <div className="each-card">
            <img
              src="/assets/imgs/data-processing.png"
              alt="data-process"
              width={150}
              height="auto"
            />
            <Text
              className="desp"
              preset="bodyLargeMedium"
              content="The data is processed using sophisticated algorithmms to extract key parameters to help treat patients with obstructive sleep apnea and sleep related hypoxia"
            />
          </div>
        </div>
      </div>
    </MainWrapper>
  )
}

export { TechnologySection }
