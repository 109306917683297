import { FC } from 'react'
import { Modal, ModalProps } from 'antd'
import { styled } from 'styled-components'
import { colors } from '../../../theme'

interface IModalProps extends ModalProps {
  title: string
  open: boolean
  padding?: string
  footerpadding?: string
  backgroundcolor?: string
  onCancel: () => void
}

const StyledModal = styled(Modal)<{
  $padding?: string
  $footerpadding?: string
  $backgroundcolor?: string
}>`
  & .ant-modal-content {
    padding: ${({ $padding }) => {
      return $padding ? $padding : '16px 12px'
    }};
    background: ${({ $backgroundcolor }) => {
      return $backgroundcolor ? $backgroundcolor : `${colors.white}`
    }};
    border: 1px solid ${colors.darkGrey1};
    border-radius: 24px;
    & .ant-modal-close {
      & svg {
        & path {
          fill: ${colors.blackConst} !important;
        }
      }
    }
    & .ant-modal-header {
      background: ${({ $backgroundcolor }) => {
        return $backgroundcolor ? 'transparent' : `${colors.white}`
      }};
      & .ant-modal-title {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        color: ${colors.darkBlue};
      }
    }
    & .ant-modal-footer {
      text-align: center;
      display: flex;
      justify-content: center;
      padding: ${({ $footerpadding }) => {
        return $footerpadding ? $footerpadding : '0'
      }};
      & button {
        border-radius: 8px;
        height: 41px;
        font-weight: 600;
      }
      & .ok-btn {
        flex: 1;
        background: ${colors.blue};
        color: ${colors.white};
      }
      & .cancel-btn {
        flex: 1;
        background: ${colors.white50};
        color: ${colors.blackConst};
        border: 1px solid ${colors.darkGrey1};
      }
    }
  }
`

const ModalBase: FC<IModalProps> = ({
  title,
  open,
  onCancel,
  children,
  closeIcon,
  okButtonProps,
  cancelButtonProps,
  padding,
  footerpadding,
  backgroundcolor,
  ...rest
}) => {
  return (
    <StyledModal
      centered
      title={title}
      open={open}
      onCancel={onCancel}
      wrapClassName="modal-container"
      closeIcon={closeIcon ? closeIcon : <img src="/assets/icons/close.svg" />}
      okButtonProps={{
        className: 'ok-btn',
        ...okButtonProps,
      }}
      cancelButtonProps={{
        className: 'cancel-btn',
        ...cancelButtonProps,
      }}
      destroyOnClose
      $padding={padding}
      $footerpadding={footerpadding}
      $backgroundcolor={backgroundcolor}
      {...rest}
    >
      {children}
    </StyledModal>
  )
}

export { ModalBase }
