import { FC } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { PageHeader, PrivateRoute, StaffForm } from '../../components'
import { APP_TITLE } from '../../constants'

const MainContainer = styled.main``

const StaffEditPageComponent: FC = () => {
  return (
    <>
      <Helmet>
        <title>Edit User | {APP_TITLE}</title>
      </Helmet>
      <MainContainer>
        <PageHeader />
        <StaffForm isEditMode />
      </MainContainer>
    </>
  )
}

export const StaffEditPage = PrivateRoute(StaffEditPageComponent)
