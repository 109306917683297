const QUERY_KEYS = {
  FETCH_USER: 'fetchUser',
  FETCH_ORGANISATIONS: 'fetchOrganisations',
  FETCH_CLINIC: 'fetchClinic',
  FETCH_USER_GROUP: 'fetchUserGroup',
  FETCH_USER_GROUP_PERMISSION: 'fetchUserGroupPermission',
  FETCH_STAFFS: 'fetchStaffs',
  FETCH_STAFF: 'fetchStaff',
  FETCH_ROLES: 'fetchRoles',
  FETCH_PATIENTS: 'fetchPatients',
  FETCH_ORGANIZATION_CLINICS: 'fetchingOrganizationClinics',
}

export { QUERY_KEYS }
