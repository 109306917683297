import { ACTION, PERMISSIONS_TYPE } from 'src/constants'
import { IRoleActions } from 'src/interfaces'

/**
 *
 * CRUD <-> 1111 | 0000
 * Action list order => Add Read Update Delete
 * This order should not be modified
 *
 */
const actionList: Array<IRoleActions> = [
  ACTION.ADD,
  ACTION.READ,
  ACTION.UPDATE,
  ACTION.DELETE,
]

/**
 * Encode actions into *00000* format
 */
const setRole = (actions: Array<IRoleActions>): string => {
  let value = ''

  /**
   * Remove all permission if read permission is removed
   **/
  if (!actions.includes(ACTION.READ)) {
    return PERMISSIONS_TYPE.NO_PERMISSION
  }

  actionList.forEach(action => {
    if (actions.includes(action)) {
      value += '1'
    } else {
      value += '0'
    }
  })
  return value || PERMISSIONS_TYPE.NO_PERMISSION
}

/**
 * Decode actions into readable format
 */
const getRole = (value): Array<IRoleActions> => {
  const actionValues: Array<IRoleActions> = []

  if (value) {
    ;[...value].forEach((c: string, idx: number) => {
      if (c === '1') {
        actionValues.push(actionList[idx])
      }
    })
  }
  return actionValues
}

export { getRole, setRole }
