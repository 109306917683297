import { FC } from 'react'
import { Spin } from 'antd'
import styled, { css } from 'styled-components'

interface LoaderProps {
  size?: 'small' | 'large' | 'default'
  text?: string
  height?: string
  preset?: 'default' | 'modal'
}

const LoaderWrapper = styled.div<{
  height: LoaderProps['height']
  $preset: LoaderProps['preset']
}>`
  width: 100%;
  height: ${({ height }: LoaderProps) => height || '100%'};
  display: grid;
  place-items: center;

  ${({ $preset }) =>
    $preset === 'modal' &&
    css`
      position: absolute;
      inset: 0;
      height: 100vh;
      width: 100vw;
      background-color: rgb(0 0 0 / 20%);
    `}
`

/**
 * Antd Spin Loader
 */
const Loader: FC<LoaderProps> = ({
  size = 'default',
  height,
  preset = 'default',
  ...rest
}) => {
  return (
    <LoaderWrapper height={height} $preset={preset}>
      <Spin size={size} {...rest} />
    </LoaderWrapper>
  )
}

export { Loader }
