import {
  MutationFunction,
  QueryFunction,
  QueryKey,
} from '@tanstack/react-query'
import { API } from '../lib/api'
import { DBUser, IChangePasswordDto } from '../interfaces'
import { LoginDto } from '../containers'
import { AxiosResponse } from 'axios'

const login: MutationFunction<
  { data: DBUser; token: string },
  LoginDto
> = async reqParams => {
  const { data } = await API.post('/staff/login', { ...reqParams })
  return data
}

const addStaff: MutationFunction<{ data: DBUser }, DBUser> = async payload => {
  return API.post('/staff', payload)
}

const updateStaff: MutationFunction<
  { data: DBUser },
  DBUser
> = async payload => {
  return API.put(`/staff/${payload.id}`, payload)
}

const changePassword: MutationFunction<
  { data: string },
  IChangePasswordDto
> = async reqPayload => {
  return API.put(`/staff/${reqPayload?.id}/reset-password`, reqPayload)
}

const fetchDBUser = async () => {
  const { data }: { data: { staff: DBUser } } = await API.get('/staff/me')
  return data
}

const fetchStaffs: QueryFunction<{ staffs: DBUser[]; count: number }> = async ({
  queryKey,
}) => {
  const page = queryKey?.[1] as QueryKey
  const pageSize = queryKey?.[2] as QueryKey
  const keyword = queryKey?.[3] as QueryKey
  const archived = queryKey?.[4] as QueryKey

  const { data } = await API.get(`/staff/all`, {
    params: {
      page,
      pageSize,
      keyword,
      disabled: archived,
    },
  })

  return data
}

const fetchOneStaff: QueryFunction<{ staff: DBUser }> = async id => {
  const { data } = await API.get(`/staff/${id}`)
  return data
}

const deleteStaff: MutationFunction<AxiosResponse, string> = id => {
  return API.delete(`/staff/${id}`)
}

const archiveStaff: MutationFunction<AxiosResponse, string> = id => {
  return API.put(`/staff/${id}/archive`)
}

export const staffsService = {
  login,
  addStaff,
  updateStaff,
  changePassword,
  fetchDBUser,
  fetchStaffs,
  deleteStaff,
  fetchOneStaff,
  archiveStaff,
}
