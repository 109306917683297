import { FC, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { APP_TITLE, ERROR_MESSAGES, QUERY_KEYS } from '../../../constants'
import { VALIDATION_SCHEMA } from '../../../utils'
import { Button, Select, Text, TextField } from '../../atom'
import { DBUser } from '../../../interfaces'
import { staffsService } from '../../../services'
import { colors } from '../../../theme'
import {
  useOrganisationClinics,
  useOrganisations,
  useStaffById,
  useUserGroup,
} from 'src/queries'

const MainContainer = styled.section`
  & .page-header {
    display: flex;
    align-items: center;
    & .icon-back {
      margin-right: 0.5rem;
    }
  }
  & form {
    & .field-wrapper {
      display: flex;
      min-height: 60vh;
      gap: 2rem;
      background: ${colors.white};
      padding: 1.5rem 2rem;
      border-radius: 8px;
      box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
      margin-top: 2rem;
      & .form-fields {
        margin-bottom: 1.5rem;
      }
      & > div {
        flex: 1;
      }
    }
    & .btn-wrapper {
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;
      margin-top: 1rem;
    }
  }
`

interface IUserForm {
  isEditMode?: boolean
}

const StaffForm: FC<IUserForm> = ({ isEditMode = false }) => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const queryClient = useQueryClient()
  const { data: organizationData } = useOrganisations({ enabled: true })

  const { data: userGroups } = useUserGroup({ enabled: true })
  const { data: staffData } = useStaffById({
    enabled: !!state?.id,
    id: state?.id,
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
    watch,
    setValue,
  } = useForm<DBUser & { confirmPassword: string; isEditMode: boolean }>({
    defaultValues: {
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      userGroupId: undefined,
      organizationId: undefined,
      clinicId: undefined,
    },
    mode: 'all',
    resolver: zodResolver(
      isEditMode
        ? VALIDATION_SCHEMA.userValidationSchema.omit({
            password: true,
            confirmPassword: true,
          })
        : VALIDATION_SCHEMA.userValidationSchema,
    ),
  })

  const watchName = watch('organizationId')

  const { data: clinicData } = useOrganisationClinics({
    enabled: !!watchName,
    organizationId: watchName,
  })

  useEffect(() => {
    if (!isDirty) {
      reset(staffData)
    }
  }, [staffData])

  const updateUser = useMutation({
    mutationKey: ['updateUser'],
    mutationFn: staffsService.updateStaff,
    onSuccess: () => {
      toast.success('Staff updated successfully')
      navigate('/users')
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FETCH_STAFFS],
      })
      reset()
    },

    onError: (err: Error) => {
      toast.error(err?.message || ERROR_MESSAGES.ERROR_OCCURED)
    },
  })

  const addUser = useMutation({
    mutationKey: ['addUser'],
    mutationFn: staffsService.addStaff,
    onSuccess: () => {
      toast.success('Staff added successfully')
      reset()
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FETCH_STAFFS],
      })
    },

    onError: (err: Error) => {
      toast.error(err?.message || ERROR_MESSAGES.ERROR_OCCURED)
    },
  })

  const handleUserSubmit = async (payload: DBUser) => {
    try {
      const savePayload = {
        ...payload,
      }

      if (isEditMode) {
        updateUser.mutate({
          ...savePayload,
          id: staffData?.id,
        })
      } else {
        addUser.mutate(savePayload)
      }
    } catch (err) {
      toast.error(ERROR_MESSAGES.ERROR_OCCURED)
    }
  }

  const handleOrganisationChange = e => {
    setValue('organizationId', e)
    setValue('clinicId', undefined)
  }
  return (
    <MainContainer>
      <Helmet>
        <title>
          {isEditMode ? 'Edit Staff' : 'Add Staff'} | {APP_TITLE}
        </title>
      </Helmet>
      <section>
        <div className="page-header">
          <ArrowLeftOutlined
            onClick={() => navigate('/users')}
            className="icon-back"
          />
          <Text
            content={isEditMode ? 'Edit Staff' : 'Add Staff'}
            preset="headingSmall"
          />
        </div>
        <form className="form">
          <div className="field-wrapper">
            <div>
              <Controller
                name="firstName"
                control={control}
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { error },
                }) => (
                  <TextField
                    required
                    placeholder="Enter first name"
                    label="First Name"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error?.message}
                    className="form-fields"
                  />
                )}
              />
              <Controller
                name="username"
                control={control}
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { error },
                }) => (
                  <TextField
                    required
                    placeholder="Enter username"
                    label="Username"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error?.message}
                    className="form-fields"
                  />
                )}
              />

              <Controller
                name="organizationId"
                control={control}
                render={({
                  field: { value, onBlur },
                  fieldState: { error },
                }) => (
                  <Select
                    required
                    placeholder="Select Organization"
                    label="Organization"
                    value={value}
                    options={organizationData || []}
                    onChange={handleOrganisationChange}
                    onBlur={onBlur}
                    error={error?.message}
                    className="form-fields"
                  />
                )}
              />

              {!isEditMode && (
                <Controller
                  name="password"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <TextField
                      required
                      placeholder="Enter password"
                      label="Password"
                      type="password"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={error?.message}
                      className="form-fields"
                    />
                  )}
                />
              )}

              <Controller
                name="userGroupId"
                control={control}
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { error },
                }) => (
                  <Select
                    required
                    placeholder="Select user group"
                    label="Access"
                    value={value}
                    options={
                      userGroups?.map(userGroup => {
                        return {
                          key: userGroup.id,
                          name: userGroup.name,
                          value: userGroup?.id,
                        }
                      }) || []
                    }
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error?.message}
                    className="form-fields"
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="lastName"
                control={control}
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { error },
                }) => (
                  <TextField
                    required
                    placeholder="Enter last name"
                    label="Last Name"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error?.message}
                    className="form-fields"
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { error },
                }) => (
                  <TextField
                    required
                    placeholder="Enter email"
                    label="Email"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error?.message}
                    className="form-fields"
                  />
                )}
              />

              <Controller
                name="clinicId"
                control={control}
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { error },
                }) => (
                  <Select
                    required
                    placeholder="Select Clinic"
                    label="Clinic"
                    value={value}
                    options={clinicData || []}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error?.message}
                    className="form-fields"
                  />
                )}
              />

              {!isEditMode && (
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <TextField
                      required
                      type="password"
                      placeholder="Repeat password again"
                      label="Repeat Password"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={error?.message}
                      className="form-fields"
                    />
                  )}
                />
              )}
            </div>
          </div>

          <div className="btn-wrapper">
            <Button label="Cancel" variant="link" />
            <Button
              label={isEditMode ? 'Edit' : 'Save'}
              onClick={handleSubmit(handleUserSubmit)}
              variant="primary"
            />
          </div>
        </form>
      </section>
    </MainContainer>
  )
}

export { StaffForm }
