import { z } from 'zod'
import { REGEX } from '../constants'

const login = z.object({
  email: z
    .string()
    .regex(REGEX.EMAIL_OR_USERNAME, 'Please exclude any special characters')
    .min(1, "Email Or username can't be empty"),
  password: z.string().min(1, "Password can't be empty"),
})

export const userValidationSchema = z.object({
  username: z
    .string()
    .min(1, "Username can't be empty")
    .max(50, 'Characters limit exceeded'),
  email: z
    .string()
    .regex(REGEX.EMAIL_OR_USERNAME, 'Please exclude any special characters')
    .min(1, "Email can't be empty")
    .max(50, 'Characters limit exceeded'),
  firstName: z
    .string()
    .min(1, "Firstname can't be empty")
    .max(50, 'Characters limit exceeded'),
  lastName: z
    .string()
    .min(1, "Lastname can't be empty")
    .max(50, 'Characters limit exceeded'),
  password: z
    .string()
    .min(5, 'Password must contain at least 6 characters')
    .max(50, 'Characters limit exceeded'),
  confirmPassword: z
    .string()
    .min(5, 'Password must contain at least 6 characters')
    .max(50, 'Characters limit exceeded'),
  userGroupId: z
    .string()
    .min(1, 'Required')
    .max(50, 'Characters limit reached'),
  organizationId: z
    .string()
    .min(1, 'Required')
    .max(50, 'Characters limit reached'),
  clinicId: z.string().min(1, 'Required').max(50, 'Characters limit reached'),
})

const changePassword = z
  .object({
    newPassword: z
      .string()
      .min(6, 'Password must contain at least 6 characters.'),
    verifyPassword: z
      .string()
      .min(6, 'Password must contain at least 6 characters.'),
  })
  .refine(data => data.newPassword === data.verifyPassword, {
    path: ['verifyPassword'],
    message: 'Password doesnot match',
  })

const addRole = z.object({
  name: z
    .string()
    .min(4, 'Name must contain at least 4 characters.')
    .max(30, 'Characters limit exceeded'),
  slug: z
    .string()
    .min(4, 'Key must contain at least 4 characters.')
    .max(30, 'Characters limit exceeded'),
})

const addOrganisation = z.object({
  name: z
    .string()
    .min(3, 'Name must contain at least 3 characters.')
    .max(50, 'Characters limit exceeded'),
  email: z
    .string()
    .min(3, 'Email must contain at least 3 characters.')
    .email('Invalid email format')
    .max(50, 'Characters limit exceeded'),
  phoneNumber: z
    .string()
    .min(0)
    .max(30, 'Characters limit exceeded')
    .regex(REGEX.PHONE_NUMBER, 'Invalid phone number format')
    .optional()
    .or(z.literal('')),
  location: z.string().max(50, 'Characters limit exceeded'),
  code: z
    .string()
    .min(2, 'Code must contain at least 2 characters.')
    .max(20, 'Characters limit exceeded'),
})

const clinic = z.object({
  name: z
    .string()
    .min(2, 'Name must contain at least 2 characters.')
    .max(50, 'Characters limit exceeded'),
  email: z
    .string()
    .min(4, 'Email must contain at least 4 characters.')
    .email('Invalid email address')
    .max(50, 'Characters limit exceeded'),
  phoneNumber: z
    .string()
    .min(0)
    .max(30, 'Characters limit exceeded')
    .regex(REGEX.PHONE_NUMBER, 'Invalid phone number format')
    .optional()
    .or(z.literal('')),
  location: z.string().max(50, 'Characters limit exceeded'),
  organizationId: z
    .string()
    .min(1, 'Please select organisation')
    .max(50, 'Characters limit exceeded'),
  code: z
    .string()
    .min(2, 'Code is required')
    .max(50, 'Code cannot be longer than 50 characters'),
})

export const VALIDATION_SCHEMA = {
  login,
  userValidationSchema,
  changePassword,
  addRole,
  addOrganisation,
  clinic,
}
