import { CSSProperties, FC } from 'react'
import { Typography } from 'antd'
import styled from 'styled-components'
import { colors } from '../../../theme'

const { Paragraph, Title } = Typography

interface IText {
  content?: string
  preset?: keyof typeof $presets
  color?: string
  className?: string
}

const StyledParagraph = styled(Paragraph)<{ $color: string }>`
  color: ${({ $color }) => $color || colors.black};
  margin-bottom: 0 !important;
`

const StyledTitle = styled(Title)<{ $color: string }>`
  color: ${({ $color }) => $color || colors.black};
  margin-bottom: 0 !important;
`

type SizePreset = 'h1' | 'h2' | 'h3' | 'p1' | 'p2' | 'c1' | 'c2'

type FontPreset =
  | 'light'
  | 'normal'
  | 'medium'
  | 'semiBold'
  | 'bold'
  | 'extraBold'

const $sizeStyles: Record<SizePreset, CSSProperties> = {
  h1: { fontSize: 36, lineHeight: '40px' },
  h2: { fontSize: 24, lineHeight: '40px' },
  h3: { fontSize: 20, lineHeight: '24px' },
  p1: { fontSize: 16, lineHeight: '24px' },
  p2: { fontSize: 14, lineHeight: '22px' },
  c1: { fontSize: 12, lineHeight: '18px' },
  c2: { fontSize: 10, lineHeight: '16px' },
}

/**
 * Font weights
 */
const $fontWeightStyles: Record<FontPreset, CSSProperties> = {
  light: {
    fontWeight: 300,
  },
  normal: {
    fontWeight: 400,
  },
  medium: {
    fontWeight: 500,
  },
  semiBold: {
    fontWeight: 600,
  },
  bold: {
    fontWeight: 700,
  },
  extraBold: {
    fontWeight: 800,
  },
}

const $presets = {
  headinglarge: {
    ...$sizeStyles.h1,
    ...$fontWeightStyles.bold,
  },
  headingMedium: {
    ...$sizeStyles.h2,
    ...$fontWeightStyles.semiBold,
  },
  headingSmall: {
    ...$sizeStyles.h3,
    ...$fontWeightStyles.semiBold,
  },
  bodyLargeSemiBold: {
    ...$sizeStyles.p1,
    ...$fontWeightStyles.semiBold,
  },
  bodyLargeMedium: {
    ...$sizeStyles.p1,
    ...$fontWeightStyles.medium,
  },
  bodyLargeRegular: {
    ...$sizeStyles.p1,
    ...$fontWeightStyles.medium,
  },
  bodyMediumSemibold: {
    ...$sizeStyles.p2,
    ...$fontWeightStyles.semiBold,
  },
  bodyMediumMedium: {
    ...$sizeStyles.p2,
    ...$fontWeightStyles.medium,
  },
  bodyMediumRegular: {
    ...$sizeStyles.p2,
    ...$fontWeightStyles.normal,
  },
}

const Text: FC<IText> = ({
  content,
  preset = 'bodyLargeRegular',
  color,
  className,
}) => {
  const getTag = (preset: keyof typeof $presets) => {
    if (preset === 'headinglarge') {
      return (
        <StyledTitle
          level={1}
          className={className}
          $color={color!}
          style={{
            ...$presets[preset],
          }}
        >
          {content}
        </StyledTitle>
      )
    }
    if (preset === 'headingMedium') {
      return (
        <StyledTitle
          level={2}
          $color={color!}
          className={className}
          style={{
            ...$presets[preset],
          }}
        >
          {content}
        </StyledTitle>
      )
    }
    if (preset === 'headingSmall') {
      return (
        <StyledTitle
          level={3}
          $color={color!}
          className={className}
          style={{
            ...$presets[preset],
          }}
        >
          {content}
        </StyledTitle>
      )
    }
    return (
      <StyledParagraph
        $color={color!}
        className={className}
        style={{
          ...$presets[preset],
        }}
      >
        {content}
      </StyledParagraph>
    )
  }

  return getTag(preset)
}

export { Text }
