import styled from 'styled-components'
import { useNavigate } from 'react-router'
import { Helmet } from 'react-helmet-async'
import { Space, Tabs, Tag } from 'antd'
import { FileTextOutlined } from '@ant-design/icons'
import {
  Button,
  PageHeader,
  PrivateRoute,
  Select,
  Table,
  TextField,
} from '../../components'
import { APP_TITLE } from '../../constants'

const MainContainer = styled.main``

const OperationWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
`

const PatientsPageComponent = () => {
  const navigate = useNavigate()
  const tableHead = [
    {
      title: 'Patients',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => {
        return (
          <Tag color={status === 'Billed' ? 'green' : 'gold'}>{status}</Tag>
        )
      },
    },
    {
      title: 'Last study date',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'STUDY DURATION, HRS',
      dataIndex: 'ahi',
      key: 'ahi',
    },
    {
      title: 'AHI',
      dataIndex: 'ahi',
      key: 'ahi',
    },
    {
      title: 'DAYS PENDING',
      dataIndex: 'day',
      key: 'day',
    },
    {
      title: 'TECH NAME',
      dataIndex: 'techName',
      key: 'techName',
    },
    {
      title: 'ASSIGNED PHYSICIAN',
      dataIndex: 'physician',
      key: 'physician',
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      render: (_, __) => {
        return (
          <Space size="middle">
            <FileTextOutlined
              title="Patient book"
              onClick={() => navigate('/patients/details')}
            />
          </Space>
        )
      },
    },
  ]

  const data = [
    {
      id: '1',
      key: '1',
      name: 'Philips Lark',
      location: 'kathmandu',
      role: 'Physician',
      ahi: '4',
      day: '2',
      techName: 'Tim Beaver',
      physician: 'Dr. House',
      status: 'Billed',
      createdAt: '2024/01/01',
    },
    {
      id: '2',
      key: '2',
      name: 'Micheal jackson',
      location: 'NY',
      role: 'Physician',
      ahi: '4',
      day: '2',
      techName: 'Tim Beaver',
      physician: 'Dr. House',
      status: 'Upload pending',
      createdAt: '2024/01/01',
    },
  ]

  const items = [
    {
      label: `ALL`,
      key: '1',
      children: (
        <Table dataSource={data} columns={tableHead} pagination={false} />
      ),
    },
    {
      label: `UPLOAD PENDING`,
      key: '2',
      children: (
        <Table dataSource={data} columns={tableHead} pagination={false} />
      ),
    },
    {
      label: `SCORED`,
      key: '3',
      children: (
        <Table dataSource={data} columns={tableHead} pagination={false} />
      ),
    },
    {
      label: `INTERPRETED`,
      key: '4',
      children: (
        <Table dataSource={data} columns={tableHead} pagination={false} />
      ),
    },
    {
      label: `BILLED`,
      key: '5',
      children: (
        <Table dataSource={data} columns={tableHead} pagination={false} />
      ),
    },
  ]

  const operations = (
    <OperationWrapper>
      <TextField type="search" placeholder="Search" />
      <Select width="200px" placeholder="Select" />
      <Button label="Button" />
    </OperationWrapper>
  )

  return (
    <>
      <Helmet>
        <title>Patients | {APP_TITLE}</title>
      </Helmet>
      <MainContainer>
        <section>
          <PageHeader />
          <div className="table-wrapper">
            <Tabs tabBarExtraContent={operations} items={items} />
          </div>
        </section>
      </MainContainer>
    </>
  )
}

export const PatientsPage = PrivateRoute(PatientsPageComponent)
