import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from 'src/constants'
import { DBUser } from 'src/interfaces'
import { staffsService } from 'src/services'

/**
 * usestaffs
 *
 * @param enabled => query enable/disabled boolean
 * @param searchQuery => search string
 * @returns useQuery response
 */
export const useStaffs = ({
  enabled,
  searchQuery,
  archived,
  pageSize,
  page,
}: {
  enabled: boolean
  searchQuery?: string
  archived?: boolean
  pageSize?: number
  page?: number
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.FETCH_STAFFS, page, pageSize, searchQuery, archived],
    queryFn: staffsService?.fetchStaffs,
    enabled: enabled,
    refetchOnWindowFocus: false,
    retry: 1,
    select: response => {
      return {
        staffs: response?.staffs?.map((staff: DBUser) => {
          return {
            key: staff?.id,
            id: staff?.id,
            email: staff?.email,
            firstName: staff?.firstName,
            lastName: staff?.lastName,
            username: staff?.username,
            disabled: staff?.disabled,
            createdAt: staff?.createdAt,
            userGroup: staff?.userGroup,
            clinic: staff?.clinic,
            organization: staff?.organization,
          }
        }),
        count: response?.count,
      }
    },
  })
}

/**
 * useStaffById
 *
 * @param enabled => query enable/disabled boolean
 * @returns useQuery response
 */
export const useStaffById = ({
  enabled,
  id,
}: {
  enabled: boolean
  id: any
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.FETCH_STAFF],
    queryFn: () => staffsService?.fetchOneStaff(id),
    enabled: enabled,
    refetchOnWindowFocus: false,
    retry: 1,
    select: response => response?.staff,
  })
}
