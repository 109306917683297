import { FC } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { ArrowLeftOutlined } from '@ant-design/icons'
import {
  Text,
  PageHeader,
  PrivateRoute,
  Button,
  TextField,
  Radio,
} from '../../components'

const MainContainer = styled.section`
  & .page-header {
    display: flex;
    align-items: center;
    & .icon-back {
      margin-right: 0.5rem;
    }
  }
`

interface ISchedulepage {
  id?: boolean
}

const PatientDetailsPageComponent: FC<ISchedulepage> = () => {
  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>{'User Add'}</title>
      </Helmet>
      <MainContainer>
        <PageHeader />
        <section>
          <div className="page-header">
            <div>
              <ArrowLeftOutlined
                onClick={() => navigate('/users')}
                className="icon-back"
              />
              <Text content={'Lili Mouse'} preset="headingSmall" />
            </div>
            <div>
              <Text content={'Study 1 of 2'} preset="headingSmall" />
            </div>
            <Button content="Raw Data" />
          </div>
          <div className="container">
            <div className="date-wrapper">
              <div className="each-detail">
                <div className="label">DOB</div>
                <div className="value">11/03/1986</div>
              </div>
              <div className="each-detail">
                <div className="label">Assigned Physician</div>
                <div className="value">Dr. House</div>
              </div>
              <div className="each-detail">
                <div className="label">Date of test</div>
                <div className="value">01/02/2023 - -1/03/2024(3 night)</div>
              </div>
            </div>
            <div className="diagnosis">
              <div className="label">Diagonosis</div>
              <div className="value">
                Moderate Obstructive Sleep Apnea (G47.33) Sleep Hypoxia is not
                prolonged
              </div>
            </div>
            <div>
              <div className="lable">Indications</div>
              <div className="">
                <TextField placeholder="Enter indication" />
              </div>
            </div>
            <div className="">
              <Text content="Study Data" />
            </div>

            <div className="table">
              <div className="column">
                <div className="td">
                  <Text content="Lights out" className="label" />
                  <Text content="10:00PM" className="value" />
                </div>
                <div className="td">
                  <Text content="Total recording time" className="label" />
                  <Text content="10:00PM" className="value" />
                </div>
                <div className="td">
                  <Text content="AHI 4%" className="label" />
                  <Text content="10:00PM" className="value" />
                </div>
                <div className="td">
                  <Text content="Apnea count" className="label" />
                  <Text content="10:00PM" className="value" />
                </div>
                <div className="td">
                  <Text content="Obstructive Apnea count" className="label" />
                  <Text content="10:00PM" className="value" />
                </div>
                <div className="td">
                  <Text content="ODI 4%" className="label" />
                  <Text content="10:00PM" className="value" />
                </div>
                <div className="td">
                  <Text content="Time<89%" className="label" />
                  <Text content="10:00PM" className="value" />
                </div>
                <div className="td">
                  <Text content="HR min" className="label" />
                  <Text content="10:00PM" className="value" />
                </div>
                <div className="td">
                  <Text content="HR mean" className="label" />
                  <Text content="10:00PM" className="value" />
                </div>
              </div>
              <div className="column">
                <div className="td">
                  <Text content="Lights on" className="label" />
                  <Text content="10:00PM" className="value" />
                </div>
                <div className="td">
                  <Text content="Total sleep time" className="label" />
                  <Text content="10:00PM" className="value" />
                </div>
                <div className="td">
                  <Text content="AHI 3%" className="label" />
                  <Text content="10:00PM" className="value" />
                </div>
                <div className="td">
                  <Text content="Hypopnea Count" className="label" />
                  <Text content="10:00PM" className="value" />
                </div>
                <div className="td">
                  <Text content="Central Apnea Count" className="label" />
                  <Text content="10:00PM" className="value" />
                </div>
                <div className="td">
                  <Text content="ODI 3%" className="label" />
                  <Text content="10:00PM" className="value" />
                </div>
                <div className="td">
                  <Text content="Time < 88%" className="label" />
                  <Text content="10:00PM" className="value" />
                </div>
                <div className="td">
                  <Text content="HR max" className="label" />
                  <Text content="10:00PM" className="value" />
                </div>
              </div>
            </div>

            <div className="indication">
              <Text content="Impresssion" />
              <div className="">
                <TextField content="Enter indication" />

                <div className="">
                  <div className="template">
                    <Radio />
                    <Text content="Template 1" />
                  </div>
                  <div className="template">
                    <Radio />
                    <Text content="Template 2" />
                  </div>
                  <div className="template">
                    <Radio />
                    <Text content="Template 3" />
                  </div>
                </div>

                <div>
                  <div className="template">
                    <Radio />
                    <Text content="Template 4" />
                  </div>
                  <div className="template">
                    <Radio />
                    <Text content="Template 5" />
                  </div>
                  <div className="template">
                    <Radio />
                    <Text content="Template 6" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-container">
            <Button label="Cancel" />
            <Button label="Download PDF" />
            <Button label="Sign" />
            <Button label="Sign And Next" />
          </div>
        </section>
      </MainContainer>
    </>
  )
}

export const PatientDetailsPage = PrivateRoute(PatientDetailsPageComponent)
