import { FC, useEffect } from 'react'
import { styled } from 'styled-components'
import { useSnapshot } from 'valtio'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { modalStore } from '../../../../store'
import { Divider, TextField } from '../../../atom'
import {
  ERROR_MESSAGES,
  QUERY_KEYS,
  SUCCESS_MESSAGES,
} from '../../../../constants'
import { userGroupService } from '../../../../services'
import { IUserGroup } from '../../../../interfaces'
import { ModalBase } from '../../../organisms'
import { VALIDATION_SCHEMA } from '../../../../utils'

const Wrapper = styled.div`
  margin-top: 28px;
  margin-bottom: 28px;
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  & .info-text {
    text-align: left;
    margin-top: 0.75rem;
  }
`

const FormWrapper = styled.form`
  margin-top: 8px;
  @media (width >= 576px) {
    margin-top: 32px;
  }
`

const AddUserGroupModal: FC = () => {
  const { shouldOpen } = useSnapshot(modalStore.addUserGroup)
  const queryClient = useQueryClient()

  const addUserGroup = useMutation({
    mutationKey: ['addUserGroup'],
    mutationFn: userGroupService.addUserGroup,
    onSuccess: () => {
      modalStore.addUserGroup.close()
      toast.success(SUCCESS_MESSAGES.ADD_USER_GROUP)
      reset()
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FETCH_USER_GROUP],
      })
    },
    onError: (error: { message: string }) => {
      toast.error(error?.message || ERROR_MESSAGES.ERROR_OCCURED)
    },
  })

  const { control, handleSubmit, reset, setValue, watch } = useForm<IUserGroup>(
    {
      defaultValues: {
        name: '',
        slug: '',
      },
      mode: 'all',
      resolver: zodResolver(VALIDATION_SCHEMA.addRole),
    },
  )

  const watchName = watch('name')

  useEffect(() => {
    if (watchName) {
      setValue('slug', watchName?.replace(' ', '_')?.toLowerCase())
    }
  }, [watchName])

  const onSubmit = (data: IUserGroup) => {
    addUserGroup.mutate({
      name: data?.name,
      slug: data?.slug,
    })
  }

  return (
    <ModalBase
      title="Add User Group"
      open={shouldOpen}
      onOk={handleSubmit(onSubmit)}
      width="503px"
      cancelButtonProps={{
        style: {
          marginLeft: 12,
          marginBottom: 8,
        },
      }}
      okButtonProps={{
        loading: addUserGroup.isPending,
        style: {
          marginRight: 12,
        },
      }}
      onCancel={() => {
        modalStore.addUserGroup.close()
        reset()
      }}
      okText="Yes, Proceed"
    >
      <Wrapper>
        <FormWrapper>
          <Controller
            name="name"
            control={control}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <TextField
                type="text"
                name="name"
                label="Name"
                placeholder="Enter name"
                value={value}
                required
                onBlur={onBlur}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
          <Divider marginTop="12px" />
          <Controller
            name="slug"
            control={control}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <TextField
                disabled
                required
                type="text"
                name="slug"
                label="Key"
                placeholder="Enter key"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
        </FormWrapper>
      </Wrapper>
    </ModalBase>
  )
}

export { AddUserGroupModal }
