import React, { FC } from 'react'
import { Checkbox, CheckboxProps } from 'antd'
import styled from 'styled-components'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { CheckboxOptionType, CheckboxValueType } from 'antd/lib/checkbox/Group'
import { Text } from '../Text'
import { colors } from '../../../theme'

const { Group } = Checkbox

interface IProps extends CheckboxProps {
  type?: 'group'
  onChange?: (e: CheckboxChangeEvent | CheckboxValueType[] | boolean) => void
  options?: string[] | CheckboxOptionType[]
  label?: string
  defaultValue?: string[]
  defaultChecked?: boolean
  name?: string
  value?: string[] | boolean
  disabled?: boolean
  className?: string
  direction?: 'horizontal' | 'vertical'
}

const Wrapper = styled.div<{
  $direction: IProps['direction']
}>`
  display: flex;
  flex-direction: column;
  & .label {
    color: ${colors.black} !important;
  }
  & .ant-checkbox-group {
    display: flex;
    flex-direction: ${({ $direction }) =>
      $direction === 'horizontal' ? 'row' : 'column'};
    & .ant-checkbox-wrapper {
      & .ant-checkbox {
        & .ant-checkbox-inner {
          border: 1px solid ${colors.grey};
          width: 20px;
          height: 20px;
        }
      }
      & .ant-checkbox-checked {
        & .ant-checkbox-inner {
          background: ${colors.blue};
          border: 1px solid ${colors.blue};
        }
      }
      &:hover {
        & .ant-checkbox-checked {
          & .ant-checkbox-inner {
            background: ${colors.blue};
          }
        }
      }
      & > span:nth-child(2) {
        color: ${colors.black};
      }
    }
  }
`

const StyledCheckbox = styled(Checkbox)`
  font-size: 16px;
  & span:nth-child(2) {
    color: ${colors.white};
  }
`

const CheckBox: FC<IProps> = ({
  type,
  onChange,
  options,
  label,
  defaultValue,
  defaultChecked,
  value,
  disabled,
  className,
  direction = 'horizontal',
  ...rest
}) => {
  const getCheckBox = () => {
    switch (type) {
      case 'group':
        return (
          <Wrapper className={className} $direction={direction}>
            <Text content={label} className="label" />
            <Group
              options={options}
              defaultValue={defaultValue}
              onChange={onChange}
              value={value as CheckboxValueType[]}
              disabled={disabled}
              {...rest}
            />
          </Wrapper>
        )
      default:
        return (
          <StyledCheckbox
            onChange={onChange}
            defaultChecked={defaultChecked}
            disabled={disabled}
            value={value}
            className={className}
            {...rest}
          >
            {label}
          </StyledCheckbox>
        )
    }
  }
  return getCheckBox()
}

export { CheckBox }
