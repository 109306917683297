import { FC } from 'react'
import styled from 'styled-components'
import { Text } from '../../atom'

const Wrapper = styled.section`
  background-color: #0055cd;

  & .left-side {
    padding: 2rem 1rem;
    color: #fff;
  }
  & .right-side {
    background-image: url('/assets/imgs/banner.webp');
    background-size: cover;
    background-repeat: no-repeat;
    height: 150px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  @media (width > 768px) {
    .inner {
      display: flex;
      position: relative;

      & .left-side {
        display: flex;
        flex: 1;
        max-width: 1248px;
        padding: 100px 0;
        margin: 0 auto;
        & .desc {
          width: 400px;
          & h1 {
            color: #fff !important;
          }
        }
      }
      & .right-side {
        height: 100%;
        width: 45%;
        position: absolute;
        right: 0;
        & .button {
          & img {
            width: 170px;
          }
        }
      }
    }
  }
`

const LandinBanner: FC = () => {
  return (
    <Wrapper>
      <div className="inner">
        <div className="left-side">
          <div className="desc">
            <Text
              color="#fff"
              content="Accurate, reliable and affordable Home Sleep Apnea testing"
              preset="headinglarge"
            />
            <Text
              color="#fff"
              preset="bodyLargeRegular"
              content="ApneaScan makes  HSAT testing affordable and scalable using evidence based technology"
            />
          </div>
        </div>
        <div className="right-side">
          <div className="button">
            {/* <img
              src="./assets/icons/download-apple.svg"
              alt="apple"
              width={100}
              height="auto"
              className="store-logo"
            /> */}
          </div>
          <div className="button">
            {/* <img
              src="./assets/icons/download-google.svg"
              alt="apple"
              width={100}
              height="auto"
              className="store-logo"
            /> */}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export { LandinBanner }
