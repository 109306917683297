import { useState, useEffect } from 'react'

/**
 *
 * @param value -> number | string
 * @param delay -> number in milliseconds
 * @returns -> debounced value after waiting for delay
 */
function useDebounce<T extends number | string>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value])

  return debouncedValue
}

export { useDebounce }
