import { FC } from 'react'
import { ChangePasswordModal } from './ChangePasswordModal'
import { AddRoleModal } from './AddRoleModal'
import { AddUserGroupModal } from './AddUserGroupModal'
import { AddOrganisationModal } from './AddOrganisationModal'
import { AddClinicModal } from './AddClinicModal'
import { RoleAssignmentModal } from './RoleAssignmentModal'
import { LogOutModal } from './LogoutModal'

/**
 * Global Modal Provider
 */
const ModalProvider: FC = () => {
  return (
    <>
      <AddOrganisationModal />
      <RoleAssignmentModal />
      <ChangePasswordModal />
      <AddUserGroupModal />
      <AddClinicModal />
      <AddRoleModal />
      <LogOutModal />
    </>
  )
}

export { ModalProvider }
