import { FC, ReactElement } from 'react'
import styled from 'styled-components'
import {
  BankOutlined,
  CalendarOutlined,
  ForkOutlined,
  IdcardOutlined,
  LogoutOutlined,
  SettingOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import { useLocation } from 'react-router-dom'
import { MenuGenerator } from '../../molecules'
import { colors } from '../../../theme'
import { Divider, Text } from 'src/components/atom'
import { modalStore } from 'src/store'

const SidebarWrapper = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: auto;
  & .sidebar-icon {
    font-size: 20px;
  }
  & .ant-menu-item {
    &:active,
    &:hover {
      background: none !important;
      color: ${colors.white} !important;
    }
  }
  & .ant-menu-item-selected {
    border-right: 3px solid ${colors.green} !important;
    border-radius: 0px !important;
    & .ant-menu-title-content {
      background-color: ${colors.darkGrey2} !important;
      border-radius: 12px !important;
    }
  }
  & .footer {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
    & .menu {
      display: flex;
      padding: 8px 12px;
      border-radius: 3px;
      gap: 0.5rem;
      &:hover {
        background: #5c5c60 !important;
        cursor: pointer;
      }
    }
  }
`

interface IMenu {
  key: string
  icon: ReactElement
  name: string
  target: string
}

interface ISidebarProps {
  collapsed?: boolean
}

const SideBar: FC<ISidebarProps> = ({ collapsed }) => {
  const location = useLocation()

  const menusList: IMenu[] = [
    {
      key: 'admins',
      icon: <IdcardOutlined className="sidebar-icon" />,
      name: 'Admins',
      target: '/admins',
    },
    {
      key: 'organisations',
      icon: <BankOutlined className="sidebar-icon" />,
      name: 'Organisations',
      target: '/organisations',
    },
    {
      key: 'scheduling',
      icon: <CalendarOutlined className="sidebar-icon" />,
      name: 'Scheduling',
      target: '/scheduling',
    },
    {
      key: 'staffs',
      icon: <TeamOutlined className="sidebar-icon" />,
      name: 'Staffs',
      target: '/staffs',
    },
    {
      key: 'patients',
      icon: <ForkOutlined className="sidebar-icon" />,
      name: 'Patients',
      target: '/patients',
    },
  ]

  const handleLogout = () => {
    modalStore.logOut.open()
  }

  return (
    <SidebarWrapper>
      <div className="logo">
        {collapsed ? (
          <img
            src="/assets/imgs/logo.webp"
            alt="logo"
            width="100px"
            onClick={() => {}}
          />
        ) : (
          <img
            src="/assets/imgs/logo.webp"
            alt="logo"
            onClick={() => {}}
            width="100px"
          />
        )}
      </div>

      <MenuGenerator
        menus={menusList}
        className="menu-generator"
        defaultSelectedKeys={['staffs']}
        defaultOpenKeys={['staffs']}
        selectedKeys={[location.pathname.split('/')?.[1]]}
      />

      <div className="footer">
        <div className="menu">
          <SettingOutlined style={{ color: '#fff', fontSize: 20 }} />
          <Text content="Settings" color="#fff" preset="bodyMediumRegular" />
        </div>
        <Divider height="5px" />

        <div className="menu" onClick={handleLogout}>
          <LogoutOutlined style={{ color: '#fff', fontSize: 20 }} />
          <Text content="Logout" color="#fff" preset="bodyMediumRegular" />
        </div>
      </div>
    </SidebarWrapper>
  )
}

export { SideBar }
