import { FC, useCallback } from 'react'
import { styled } from 'styled-components'
import { useSnapshot } from 'valtio'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { modalStore } from '../../../../store'
import { Divider, Select, TextField } from '../../../atom'
import {
  ERROR_MESSAGES,
  QUERY_KEYS,
  ROLE_OPTIONS,
  SUCCESS_MESSAGES,
} from '../../../../constants'
import { roleService } from '../../../../services'
import { IRole } from '../../../../interfaces'
import { ModalBase } from '../../../organisms'
import { VALIDATION_SCHEMA } from '../../../../utils'
import { useRoles } from 'src/queries'

const Wrapper = styled.div`
  margin-top: 28px;
  margin-bottom: 28px;
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  & .info-text {
    text-align: left;
    margin-top: 0.75rem;
  }
`

const FormWrapper = styled.form`
  margin-top: 8px;
  @media (width >= 576px) {
    margin-top: 32px;
  }
`

const AddRoleModal: FC = () => {
  const { shouldOpen } = useSnapshot(modalStore.addRole)
  const { data: rolesData } = useRoles({ enabled: shouldOpen })
  const queryClient = useQueryClient()

  const addRole = useMutation({
    mutationKey: ['addRole'],
    mutationFn: roleService.addRole,
    onSuccess: () => {
      modalStore.addRole.close()
      toast.success(SUCCESS_MESSAGES.ADD_ROLE)
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FETCH_ROLES],
      })
      reset()
    },
    onError: (error: { message: string }) => {
      toast.error(error?.message || ERROR_MESSAGES.ERROR_OCCURED)
    },
  })

  const { control, handleSubmit, reset, setValue } = useForm<IRole>({
    defaultValues: {
      name: undefined,
      slug: '',
    },
    mode: 'all',
    resolver: zodResolver(VALIDATION_SCHEMA.addRole),
  })

  const handleRoleChange = useCallback(e => {
    setValue('name', e)
    setValue('slug', e)
  }, [])

  const onSubmit = useCallback((selectedRole: IRole) => {
    selectedRole.name = ROLE_OPTIONS.filter(
      role => role.value === selectedRole.slug,
    )?.[0]?.name

    addRole.mutate({
      ...selectedRole,
    })
  }, [])

  return (
    <ModalBase
      title="Add Role"
      open={shouldOpen}
      onOk={handleSubmit(onSubmit)}
      width="503px"
      cancelButtonProps={{
        style: {
          marginLeft: 12,
          marginBottom: 8,
        },
      }}
      okButtonProps={{
        loading: addRole.isPending,
        style: {
          marginRight: 12,
        },
      }}
      onCancel={() => {
        modalStore.addRole.close()
        reset()
      }}
      okText="Yes, Proceed"
    >
      <Wrapper>
        <FormWrapper>
          <Controller
            name="name"
            control={control}
            render={({ field: { value, onBlur }, fieldState: { error } }) => (
              <Select
                name="name"
                label="Name"
                placeholder="Select role"
                value={value}
                required
                options={ROLE_OPTIONS?.filter(role => {
                  const isRoleDataExist = rolesData?.some(
                    data => data.slug === role.value,
                  )
                  return !isRoleDataExist
                })}
                onBlur={onBlur}
                onChange={handleRoleChange}
                error={error?.message}
              />
            )}
          />
          <Divider marginTop="12px" />
          <Controller
            name="slug"
            control={control}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <TextField
                required
                type="text"
                name="slug"
                label="Key"
                placeholder="Enter key"
                value={value}
                disabled
                onBlur={onBlur}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
        </FormWrapper>
      </Wrapper>
    </ModalBase>
  )
}

export { AddRoleModal }
