import { useSnapshot } from 'valtio'
import { toast } from 'react-toastify'
import { ERROR_MESSAGES, SESSION_KEY } from 'src/constants'
import { staffsService } from 'src/services'
import { authStore } from 'src/store'
import { getItemFromLocalStorage, removeItemFromLocalStorage } from 'src/utils'

const useClientAuthentication = () => {
  const token = getItemFromLocalStorage(SESSION_KEY)
  const { dbUser, loggedIn, tokenFetching } = useSnapshot(authStore)

  const fetchLoggedInUser = async () => {
    try {
      authStore.setTokenFetching(true)
      const data = await staffsService.fetchDBUser()
      if (data) {
        authStore.setDbUser(data?.staff)
        authStore.setLoggedIn()
      }
    } catch (err) {
      toast.error(ERROR_MESSAGES.ERROR_OCCURED)
    } finally {
      authStore.setTokenFetching(false)
    }
  }

  if (token) {
    try {
      if (!dbUser?.id && !loggedIn && !tokenFetching) {
        fetchLoggedInUser()
      }
    } catch (error) {
      authStore.setLogout()
      removeItemFromLocalStorage(SESSION_KEY)
    }
  } else {
    authStore.setLogout()
  }

  return { token }
}

export { useClientAuthentication }
