import React, { CSSProperties } from 'react'
import { Pagination, PaginationProps } from 'antd'
import styled from 'styled-components'
import { colors } from 'src/theme'

interface Iprops extends PaginationProps {
  containerStyle?: CSSProperties
  current?: number
}

const PaginationWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 120px;

  & .ant-pagination-item-active,
  .ant-pagination-item-link-icon {
    border-color: ${colors.black} !important;
    color: ${colors.blue} !important;
  }
  & .ant-pagination-item-active,
  li:hover {
    & a {
      color: ${colors.darkGrey1};
    }
    border-color: ${colors.green};
  }
`
const PaginationComponent: React.FC<Iprops> = ({
  current,
  hideOnSinglePage,
  total,
  pageSize,
  onChange,
  onShowSizeChange,
  containerStyle,
  ...rest
}) => {
  return (
    <PaginationWrapper style={containerStyle}>
      <Pagination
        responsive
        current={+current!}
        hideOnSinglePage={hideOnSinglePage}
        total={total}
        pageSize={pageSize}
        onChange={onChange}
        onShowSizeChange={onShowSizeChange}
        {...rest}
      />
    </PaginationWrapper>
  )
}

export { PaginationComponent as Pagination }
