import { FC } from 'react'
import { styled } from 'styled-components'
import { useSnapshot } from 'valtio'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { modalStore } from '../../../../store'
import { Divider, TextField } from '../../../atom'
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from '../../../../constants'
import { staffsService } from '../../../../services'
import { IChangePasswordDto } from '../../../../interfaces'
import { ModalBase } from '../../../organisms'
import { VALIDATION_SCHEMA } from '../../../../utils'

const Wrapper = styled.div`
  margin-top: 28px;
  margin-bottom: 28px;
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  & .info-text {
    text-align: left;
    margin-top: 0.75rem;
  }
`

const FormWrapper = styled.form`
  margin-top: 8px;
  @media (width >= 576px) {
    margin-top: 32px;
  }
`

const ChangePasswordModal: FC = () => {
  const { shouldOpen, staffId } = useSnapshot(modalStore.changePassword)

  const changePassword = useMutation({
    mutationKey: ['changePassword'],
    mutationFn: staffsService.changePassword,
    onSuccess: () => {
      modalStore.changePassword.close()
      toast.success(SUCCESS_MESSAGES.CHANGE_PASSWORD)
      reset()
    },
    onError: (error: { message: string }) => {
      toast.error(error?.message || ERROR_MESSAGES.ERROR_OCCURED)
    },
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IChangePasswordDto>({
    defaultValues: {
      newPassword: '',
      verifyPassword: '',
    },
    mode: 'all',
    resolver: zodResolver(VALIDATION_SCHEMA.changePassword),
  })

  const onSubmit = (data: IChangePasswordDto) => {
    changePassword.mutate({
      id: staffId,
      newPassword: data?.newPassword,
    })
  }

  return (
    <ModalBase
      title="Change Password"
      open={shouldOpen}
      onOk={handleSubmit(onSubmit)}
      width="503px"
      cancelButtonProps={{
        style: {
          marginLeft: 12,
          marginBottom: 8,
        },
      }}
      okButtonProps={{
        loading: changePassword.isPending,
        style: {
          marginRight: 12,
        },
      }}
      onCancel={() => {
        modalStore.changePassword.close()
        reset()
      }}
      okText="Yes, Proceed"
    >
      <Wrapper>
        <FormWrapper>
          <Controller
            name="newPassword"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <TextField
                required
                type="password"
                name="newPassword"
                label="New Password"
                placeholder="Type New password"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={errors?.newPassword?.message}
              />
            )}
          />
          <Divider marginTop="12px" />
          <Controller
            name="verifyPassword"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <TextField
                required
                type="password"
                name="verifyPassword"
                label="Repeat New Password"
                placeholder="Repeat New password"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={errors?.verifyPassword?.message}
              />
            )}
          />
        </FormWrapper>
      </Wrapper>
    </ModalBase>
  )
}

export { ChangePasswordModal }
