import React, { FC, ReactNode } from 'react'
import { Switch } from 'antd'
import styled from 'styled-components'
import { SwitchChangeEventHandler } from 'antd/lib/switch'
import { colors } from 'src/theme'

interface SwitchProps {
  checked: boolean
  onChange?: SwitchChangeEventHandler
  disabled?: boolean
  loading?: boolean
  size?: 'default' | 'small'
  onClick?: SwitchChangeEventHandler
  label?: string
  className?: string
  title?: string
  checkedChildren?: ReactNode
  unCheckedChildren?: ReactNode
}

const StyledSwitch = styled.div`
  & .ant-switch {
    background-color: ${colors.darkGrey2};
  }
  & .ant-switch-checked {
    background-color: ${colors.blue};
  }
`

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
`

const SwitchComponent: FC<SwitchProps> = ({
  checked,
  className,
  label,
  title,
  checkedChildren,
  unCheckedChildren,
  ...rest
}) => {
  return (
    <StyledSwitch className={className}>
      {label && <Label>{label}</Label>}
      <Switch
        checked={checked}
        {...rest}
        title={title}
        style={{ width: 20 }}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
      />
    </StyledSwitch>
  )
}

export { SwitchComponent as Switch }
