/* eslint-disable */

import { FC } from 'react'
import './policy.css'

const PrivacyPolicyPage: FC = () => {
  return (
    <>
      <div className="p4 doc-content">
        <p className="p1">
          <span className="po">PRIVACY POLICY</span>
        </p>
        <p className="p1">
          <span className="po">
            ApneaScan SaMD and Apneascan mobile application.{' '}
          </span>
        </p>
        <p className="p1">
          <span className="po">Date of Last Revision: January 10, 2023</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span>
            Burnside Health, Inc dba ApneaScan (&ldquo;Burnside Health&rdquo;,
            &ldquo;we&rdquo; or &ldquo;us&rdquo;) provides US Food and Drug
            Administration (FDA)-cleared software as a medical device technology
            (&ldquo;Technology&rdquo;) that accesses, collects, and stores
            certain information and data from third-party devices. &nbsp;
          </span>
          <span className="p3">
            This technology is only to be used under the prescription and
            guidance of a qualified healthcare professional.
          </span>
          <span className="po">&nbsp;</span>
        </p>
        <p className="p1">
          <span>
            The purpose of this Technology is to aid clinical evaluation of
            sleep quality and sleep disorders.The Technology utilizes this data,
            independently or in combination with other data, for DAP [
          </span>
          <span className="p5">
            decreases in the amplitude fluctuations of photopletysmography]
          </span>
          <span className="po">
            &nbsp;analysis providing physiological data for clinical analysis
            (&ldquo;Sleep Data&rdquo;). The intended users of this Technology
            are healthcare professionals, for the evaluation of sleep disorders
            and the provision of Sleep Data information about patients under
            their care for health and wellness purposes.
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">THE APNEASCAN OFFERINGS</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            As described in this Privacy Policy (the &ldquo;Policy&rdquo;), we
            collect information from visitors to our website found at
            www.ApneaScan.com and from patients under the care of healthcare
            professionals through our related mobile application (the
            &ldquo;App&rdquo;). The collected information includes personally
            identifiable information (&ldquo;PII&rdquo;). By engaging with the
            ApneaScan Offerings, Users consent to the collection, use, sharing,
            and storage of their personal information, as described in this
            Policy. This Policy is incorporated into and is subject to the
            Burnside Health Terms of Use Agreement (the
            &ldquo;Agreement&rdquo;).
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">CHANGES TO OUR POLICY</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            Burnside Health reserves the right to modify this Policy from time
            to time, and any such modification shall be immediately effective
            upon posting. If there are material changes, notice will be provided
            through email or posting on the ApneaScan Offerings. Continued use
            of the ApneaScan Offerings after changes to this Policy is deemed
            acceptance of those changes.
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">PRIVACY PRINCIPLES</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            We adhere to the following privacy principles:
          </span>
        </p>
        <p className="p1">
          <span className="po">
            - We collect only the necessary PII for the full functions of the
            ApneaScan Offerings.
          </span>
        </p>
        <p className="p1">
          <span className="po">
            - PII is used for specified purposes outlined in this Policy.
          </span>
        </p>
        <p className="p1">
          <span className="po">
            - PII is not retained longer than deemed necessary.
          </span>
        </p>
        <p className="p1">
          <span className="po">
            - Except as specified in this Policy, we do not share PII with third
            parties.
          </span>
        </p>
        <p className="p1">
          <span className="po">
            - Protected Health Information (PHI) is a subset of PII, and
            compliance with applicable laws, including HIPAA, is paramount.
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">TYPES OF INFORMATION WE COLLECT</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            When Clinicians or Patients interact with the Website, App, or
            Technology, they may provide us with PII, including PHI, and
            non-personally identifiable Usage Data. PII includes information
            about Clinicians and Patients, and PHI is handled in accordance with
            HIPAA and other Privacy Laws.
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            NECESSITY OF PERSONALLY IDENTIFIABLE INFORMATION (PII)
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            PII is deemed essential for various reasons. Firstly, the Technology
            holds FDA clearance as a medical device software, enabling its
            utilization by Clinicians and Patients under their care.
            Furthermore, Patients have the option to instruct Burnside Health to
            share their Protected Health Information (PHI) with their healthcare
            professional, who may also be a Clinician via the App, or vice
            versa. In such instances, it becomes imperative for Clinicians and
            Patients to furnish specific PII to establish their identities.
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            When Clinicians initiate the account setup and registration process,
            the following information may be collected:
          </span>
        </p>
        <p className="p1">
          <span className="po">- Name</span>
        </p>
        <p className="p1">
          <span className="po">- E-mail address</span>
        </p>
        <p className="p1">
          <span className="po">- Medical specialty</span>
        </p>
        <p className="p1">
          <span className="po">- Physical address</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            For Patients authenticating their identity to access the App, the
            information collected includes:
          </span>
        </p>
        <p className="p1">
          <span className="po">- Date of birth</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            Additionally, during the authentication process for App usage, the
            following information is randomly generated:
          </span>
        </p>
        <p className="p1">
          <span className="po">- Patient&rsquo;s unique patient ID</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            While Clinicians and Patients retain the option to abstain from
            providing PII, it is underscored that the creation and setup of an
            account serve as prerequisites for accessing the complete
            functionalities offered by the ApneaScan platform.
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">CLINICIAN DATA ENTRY INTO THE TECHNOLOGY</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            Clinicians have the capability to input information into the
            Technology on behalf of the Patients under their supervision and/or
            responsibility. This information encompasses, but is not limited to:
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">- Name</span>
        </p>
        <p className="p1">
          <span className="po">- Email Address</span>
        </p>
        <p className="p1">
          <span className="po">- Gender</span>
        </p>
        <p className="p1">
          <span className="po">- Time zone location</span>
        </p>
        <p className="p1">
          <span className="po">- Patient ID</span>
        </p>
        <p className="p1">
          <span className="po">- Date of birth</span>
        </p>
        <p className="p1">
          <span className="po">- Height</span>
        </p>
        <p className="p1">
          <span className="po">- Weight</span>
        </p>
        <p className="p1">
          <span className="po">
            - Body Mass Index (BMI) calculated based on entered height and
            weight
          </span>
        </p>
        <p className="p1">
          <span className="po">- Response to Epworth Sleepiness Scale</span>
        </p>
        <p className="p1">
          <span className="po">- Sleep Complaints</span>
        </p>
        <p className="p1">
          <span className="po">- Medical History</span>
        </p>
        <p className="p1">
          <span className="po">- Medications Used</span>
        </p>
        <p className="p1">
          <span className="po">- Treatment Devices Used</span>
        </p>
        <p className="p1">
          <span className="po">
            - Clinician&rsquo;s Diagnosis of a Sleep Disorder
          </span>
        </p>
        <p className="p1">
          <span className="po">
            - Clinician&rsquo;s Impressions and Recommendations based on Test
            Results
          </span>
        </p>
        <p className="p1">
          <span className="po">
            - Other clinical information deemed appropriate by the Clinician
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            It is pertinent to note that some of the information entered about
            the Patients may fall under the category of Protected Health
            Information (PHI). Furthermore, when utilizing a Clinician&rsquo;s
            account, the Technology may also gather various types of information
            about their Patients, some of which may also constitute PHI. This
            includes:
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            - Physiological data (including, but not limited to:
            Electrocardiogram; Plethysmogram; Heart Rate; Heart (Pulse) Rate
            Variability; Electrocardiogram (Plethysmogram) Derived Respiration)
          </span>
        </p>
        <p className="p1">
          <span className="po">
            - Accelerometer data (including, but not limited to: Actigraphy,
            Body Position, Snoring)
          </span>
        </p>
        <p className="p1">
          <span className="po">
            - Oxygen Saturation (SpO2) data (including, but not limited to:
            Desaturation events, Oxygen Desaturation Index (ODI), Apnea Hypopnea
            Index (pAHI).
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">HIPAA COMPLIANCE</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            Protected Health Information (PHI) is accorded special protections.
            In the event of creating, receiving, maintaining, or transmitting
            PHI, we adhere strictly to the provisions of the Health Insurance
            Portability and Accountability Act (HIPAA) and other pertinent state
            and federal laws and regulations collectively referred to as
            &quot;Privacy Laws.&quot; Although we do not fall under the
            classification of a HIPAA covered entity, there exists a possibility
            of serving as a business associate to specific healthcare
            professionals, including Clinicians.
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            Should the scenario of a business associate relationship arise, the
            terms outlined in the business associate agreement, an integral part
            of the overarching Agreement, come into effect. In instances where
            HIPAA is applicable, each Clinician explicitly assures and warrants
            the preservation of the privacy of all Patients&#39; PHI in
            compliance with HIPAA and other relevant laws. Furthermore,
            Clinicians assert that they possess all requisite rights to furnish
            Patients&#39; PHI to us or authorize us to create, receive,
            maintain, and/or transmit PHI through the Technology.
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            Patients, under HIPAA, hold certain rights, and for further details
            on these rights, they are encouraged to consult their healthcare
            professionals. In cases where the provisions of this Policy are at
            odds with Privacy Laws, our adherence aligns with the stipulations
            of the Privacy Laws.
          </span>
        </p>
        <p className="p1">
          <span className="po">USAGE DATA AND COOKIES</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            To enhance and optimize our services, we may gather non-personally
            identifiable Usage Data pertaining to the utilization of the
            ApneaScan Offerings. This data is automatically collected through
            various standard web measurement and tracking technologies, such as
            &quot;cookies,&quot; web server logs, or other statistical programs.
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            The utilization of cookies serves several purposes, including:
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            - Authentication: Facilitating the authentication of Clinicians and
            Patients to provide personalized content.
          </span>
        </p>
        <p className="p1">
          <span className="po">
            - Security: Safeguarding Users, ourselves, and others by aiding in
            the detection of fraud and other violations of the Agreement.
          </span>
        </p>
        <p className="p1">
          <span className="po">
            -Performance: Enhancing the ease and speed of use for the ApneaScan
            Offerings.
          </span>
        </p>
        <p className="p1">
          <span className="po">
            -Features: Enabling features and storing information about
            Clinicians and Patients and their interactions with the ApneaScan
            Offerings.
          </span>
        </p>
        <p className="p1">
          <span className="po">
            - Analytics and Research: Monitoring and assessing the usage
            patterns of the ApneaScan Offerings.
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            Users have the option in their browser settings to accept, reject,
            or receive notifications about cookies. However, it&#39;s important
            to note that restrictive browser settings may impact the usability
            of the ApneaScan Offerings. Users may also have the ability to opt
            out of third-party advertiser and ad network placement of cookies
            for targeted advertising. Deleting browser cookies will result in
            the deletion of the opt-out cookie, and if users switch computers or
            web browsers, they will need to opt out again. Any pairing of this
            Usage Data with a Clinician or Patient&#39;s Personally Identifiable
            Information (PII) will be handled in accordance with this Policy.
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">We employ the following cookies:</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">- Cookie Name: Sid</span>
        </p>
        <p className="p1">
          <span className="po">- Purpose: User session key</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">DE-IDENTIFIED DATA</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            We may de-identify Personally Identifiable Information (PII) to
            render it anonymous and utilize it in an aggregate form, either
            independently or combined with Usage Data. This process aids in
            generating reports about trends, analyses of treatments, disorders,
            or conditions, demographic information, and performance insights
            related to the ApneaScan Offerings. This de-identified information,
            termed &quot;Aggregate Data,&quot; does not personally identify
            Clinicians or Patients. We retain the right to share Aggregate Data
            with third parties, including for research or marketing purposes, or
            to publish Aggregate Data in white papers, publications, or reports.
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            Should de-identification involve Protected Health Information (PHI),
            we commit to executing this process in compliance with Privacy Laws
            and the U.S. Department of Health and Human Services&#39; Guidance
            Regarding Methods for De-identification of Protected Health
            Information in Accordance with the Health Insurance Portability and
            Accountability Act (HIPAA) Privacy Rule.
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">DATA SECURITY</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            Burnside Health employs reasonable physical, administrative, and
            technical safeguards to protect the integrity and security of PII.
            While we strive to secure information, electronic transmission has
            inherent risks. Clinicians and Patients are responsible for keeping
            authentication credentials confidential.
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">HOW WE USE INFORMATION</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            We use the collected information for various purposes, including but
            not limited to:
          </span>
        </p>
        <p className="p1">
          <span className="po">
            - Providing, developing, maintaining, operating, and improving the
            ApneaScan Offerings.
          </span>
        </p>
        <p className="p1">
          <span className="po">
            - Sharing Patients&rsquo; PHI with their Clinicians.
          </span>
        </p>
        <p className="p1">
          <span className="po">
            - Sending service messages and information about the ApneaScan
            Offerings.
          </span>
        </p>
        <p className="p1">
          <span className="po">
            - Communicating with Clinicians and Patients for customer service
            and promotional events.
          </span>
        </p>
        <p className="p1">
          <span className="po">
            - Enforcing contractual obligations and rights.
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">SHARING INFORMATION</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            We may disclose information to service providers, business partners,
            and others supporting our business, following this Policy and
            applicable law. Consent is obtained when required. PII will not be
            sold, rented, or provided to third parties for their purposes. PII
            may be shared with third parties in the event of a business
            transfer.
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">DATA RETENTION</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            We retain Clinician and Patient information as needed to fulfill
            requests, provide services, comply with legal obligations, resolve
            disputes, and enforce agreements.
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">EMAIL COMMUNICATIONS</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            Clinicians and Patients can unsubscribe from marketing emails,
            though service-related communications are non-optional.
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            UNSUBSCRIBING, REMOVING OR MODIFYING INFORMATION
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            Clinicians and Patients can edit or delete information in their
            accounts, unsubscribe from emails, or contact us to request access,
            correction, or deletion of PII.
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">CALIFORNIA RESIDENTS</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            California residents have the right to request information about
            disclosed PII for direct marketing purposes. Burnside Health
            currently does not share PII for such purposes.
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">OTHER TERMS AND CONDITIONS</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            Access and use of the ApneaScan Offerings are subject to the
            Agreement, which includes the disclaimer that Burnside Health does
            not provide medical advice. Users are encouraged to consult their
            designated Clinician regarding Sleep Data and related conditions.
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">QUESTIONS?</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            For inquiries about this Policy or Burnside Health&rsquo;s
            information practices, contact the Privacy Office at:
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">Privacy Office (Legal Department) &nbsp;</span>
        </p>
        <p className="p1">
          <span className="po">Burnside Health &nbsp;</span>
        </p>
        <p className="p1">
          <span className="po">12405 SE 2nd Cir, </span>
        </p>
        <p className="p1">
          <span className="po">Vancouver WA 98684</span>
        </p>
        <p className="p1">
          <span className="po">Email: support@ApneaScan.com</span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1">
          <span className="po">
            *ApneaScan&reg; is a registered trademark of Burnside Health Inc.*
          </span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
        <p className="p1 p2">
          <span className="po"></span>
        </p>
      </div>
    </>
  )
}

export { PrivacyPolicyPage }
