import {
  MutationFunction,
  QueryFunction,
  QueryKey,
} from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { IClinic } from 'src/interfaces/clinic'
import { API } from 'src/lib'

interface IQueryKey {
  queryKey?: QueryKey[]
}

const fetchClinics: QueryFunction<IClinic[], IQueryKey[]> = async ({
  queryKey,
}) => {
  const keyword = queryKey?.[3] as QueryKey
  const page = queryKey?.[1] as QueryKey
  const pageSize = queryKey?.[2] as QueryKey

  const { data } = await API.get(`/clinic/all`, {
    params: {
      page,
      pageSize,
      keyword,
    },
  })
  return data
}

const addClinic: MutationFunction<
  { data: string },
  IClinic
> = async reqPayload => {
  return API.post('/clinic', reqPayload)
}

const enableClinic: MutationFunction<AxiosResponse, { id: string }> = data => {
  const id = data?.id
  return API.put(`/clinic/${id}/enable`)
}

const disableClinic: MutationFunction<AxiosResponse, { id: string }> = data => {
  const id = data?.id
  return API.put(`/clinic/${id}/disable`)
}

export const clinicService = {
  addClinic,
  fetchClinics,
  enableClinic,
  disableClinic,
}
