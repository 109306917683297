import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import { Button, PageHeader, PrivateRoute } from '../../components'
import { APP_TITLE } from '../../constants'
import { useNavigate } from 'react-router'

const MainContainer = styled.section``

const SchedulingPageComponent = () => {
  const navigate = useNavigate()
  return (
    <>
      <Helmet>
        <title>Schedule | {APP_TITLE}</title>
      </Helmet>
      <MainContainer>
        <PageHeader />

        <Button
          label="Schedule Patient"
          onClick={() => navigate('/scheduling/patients')}
        />
      </MainContainer>
    </>
  )
}

export const SchedulingPage = PrivateRoute(SchedulingPageComponent)
