import { FC } from 'react'
import { styled } from 'styled-components'
import { useSnapshot } from 'valtio'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { modalStore } from '../../../../store'
import { Divider, TextField } from '../../../atom'
import {
  ERROR_MESSAGES,
  QUERY_KEYS,
  SUCCESS_MESSAGES,
} from '../../../../constants'
import { organisationService } from '../../../../services'
import { ModalBase } from '../../../organisms'
import { VALIDATION_SCHEMA } from '../../../../utils'
import { IOrganization } from 'src/interfaces'

const Wrapper = styled.div`
  margin-top: 28px;
  margin-bottom: 28px;
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  & .info-text {
    text-align: left;
    margin-top: 0.75rem;
  }
`

const FormWrapper = styled.form`
  margin-top: 8px;
  @media (width >= 576px) {
    margin-top: 32px;
  }
`

const AddOrganisationModal: FC = () => {
  const { shouldOpen } = useSnapshot(modalStore.addOrganisation)
  const queryClient = useQueryClient()

  const addOrganisation = useMutation({
    mutationKey: ['addOrganisation'],
    mutationFn: organisationService.addOrganisation,
    onSuccess: () => {
      modalStore.addOrganisation.close()
      toast.success(SUCCESS_MESSAGES.ADD_ORGANISATION)
      reset()
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FETCH_ORGANISATIONS],
      })
    },
    onError: (error: { message: string }) => {
      toast.error(error?.message || ERROR_MESSAGES.ERROR_OCCURED)
    },
  })

  const { control, handleSubmit, reset } = useForm<IOrganization>({
    defaultValues: {
      name: '',
      email: '',
      location: '',
      phoneNumber: '',
      code: '',
    },
    mode: 'all',
    resolver: zodResolver(VALIDATION_SCHEMA.addOrganisation),
  })

  const onSubmit = (data: IOrganization) => {
    addOrganisation.mutate({
      ...data,
    })
  }

  return (
    <ModalBase
      title="Add Organisation"
      open={shouldOpen}
      onOk={handleSubmit(onSubmit)}
      width="503px"
      cancelButtonProps={{
        style: {
          marginLeft: 12,
          marginBottom: 8,
        },
      }}
      okButtonProps={{
        loading: addOrganisation.isPending,
        style: {
          marginRight: 12,
        },
      }}
      onCancel={() => {
        modalStore.addOrganisation.close()
        reset()
      }}
      okText="Yes, Proceed"
    >
      <Wrapper>
        <FormWrapper>
          <Controller
            name="name"
            control={control}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <TextField
                type="text"
                name="name"
                label="Name"
                placeholder="Enter name"
                value={value}
                required
                onBlur={onBlur}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
          <Divider marginTop="12px" />
          <Controller
            name="phoneNumber"
            control={control}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <TextField
                type="text"
                name="phoneNumber"
                label="Phone Number"
                placeholder="Enter phone"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />

          <Divider marginTop="12px" />
          <Controller
            name="email"
            control={control}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <TextField
                required
                type="text"
                name="email"
                label="Email"
                placeholder="Enter email"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />

          <Divider marginTop="12px" />
          <Controller
            name="location"
            control={control}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <TextField
                type="text"
                name="location"
                label="Location"
                placeholder="Enter location"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />

          <Divider marginTop="12px" />
          <Controller
            name="code"
            control={control}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <TextField
                required
                type="text"
                name="code"
                label="Code"
                placeholder="Enter code"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
        </FormWrapper>
      </Wrapper>
    </ModalBase>
  )
}

export { AddOrganisationModal }
