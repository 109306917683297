import {
  MutationFunction,
  QueryFunction,
  QueryKey,
} from '@tanstack/react-query'
import { API } from '../lib'
import { IPatient, IPatientSchedule } from '../interfaces'

const addPatient: MutationFunction<
  { data: IPatientSchedule },
  IPatientSchedule
> = async payload => {
  return API.post('/patient', payload)
}

const fetchPatients: QueryFunction<IPatient[]> = async ({ queryKey }) => {
  const keyword = queryKey?.[3] as QueryKey
  const page = queryKey?.[1] as QueryKey
  const pageSize = queryKey?.[2] as QueryKey

  const { data } = await API.get(`/patients/all`, {
    params: {
      page,
      pageSize,
      keyword,
    },
  })
  return data
}

export const patientService = {
  addPatient,
  fetchPatients,
}
